import {
  Typography,
  InputLabel,
  TextField,
  Checkbox,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import React from "react";
import {
  TopNavBar,
  PricingInput,
  AddFeaturesCard,
  DrawerComponent,
  FeaturesCard,
  SelectBox,
  TextBox,
} from "../../components";
import { withNavBars } from "../../HOCs";
import { useStylesplanDetails } from "./style";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { AlertProps } from "../../utils";
import { BackdropContext, AlertContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, Message } from "../../utils";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { loadOptionsApis } from "../../utils/asyncLoading";
import { LogoUpload } from "../../components/LogoUpload";

const PlanDetails = (props) => {
  // use classes
  const classes = useStylesplanDetails();

  const backdrop = React.useContext(BackdropContext);

  const history = useHistory();

  const alert = React.useContext(AlertContext);

  const initialState = {
    name: "",
    desc: "",
    is_public_portal: false,
    is_recommended_plan: false,
    is_metered_billing: false,
    is_active: false,
    billing_period: "",
    yearly_fees: "",
    monthly_fees: "",
    user_fee_type: "per_user",
    billing_cycles: "",
    plan_features: [],
    plan_addons: [],
    plan_charges: [],
    selected_module: [],
    load_feature: false,
    size: "",
    error: {
      name: "",
      dec: "",
      billing_period: "",
      yearly_fees: "",
      monthly_fees: "",
      user_fee_type: "",
      billing_cycles: "",
      limited: "",
      chargespricing: "",
      selected_module: "",
      size: "",
    },
  };
  const initialForPerUser = {
    company: "",
    unit: 999999,
    resource: "",
    inspection: 999999,
    property: 999999,
    display_name: "",
    community_property: 99999,
    // residential_unit: "",
    // commercial_unit: "",
    public_listing: "1000",
    private_listing: "",
    public_facility: "1000",
    private_facility: "",
    active_users: "",
    logo: "",
    total_area_size: "",
    seat_size: "",
    error: {
      company: "",
      unit: "",
      resource: "",
      display_name: "",
      community_property: "",
      // residential_unit: "",
      // commercial_unit: "",
      public_listing: "",
      private_listing: "",
      public_facility: "",
      private_facility: "",
      active_users: "",
      logo: "",
      total_area_size: "",
      seat_size: ""
    },
  };
  const initialForPerUnit = {
    company: "",
    unit: "",
    resource: "",
    inspection: "",
    property: 999999,
    display_name: "",
    community_property: "",
    // residential_unit: "",
    // commercial_unit: "",
    public_listing: "1000",
    private_listing: "",
    public_facility: "1000",
    private_facility: "",
    active_users: 999999,
    total_area_size: "",
    logo: "",
    seat_size: "",
    error: {
      company: "",
      unit: "",
      resource: "",
      display_name: "",
      community_property: "",
      // residential_unit: "",
      // commercial_unit: "",
      public_listing: "",
      private_listing: "",
      public_facility: "",
      private_facility: "",
      active_users: "",
      logo: "",
      total_area_size: "",
      seat_size: ""
    },
  };
  const [ChargesData, setChargesData] = React.useState([]);

  const [addData, setAddData] = React.useState([]);

  const [mapsubcription, setmapsubcription] = React.useState(false);

  // add feature genrel
  const [addFeatureDrawer, SetAddFeatureDrawer] = React.useState(false);
  const [actionCheck, setactionCheck] = React.useState(false);

  const [addChargeDrawer, setAddChargeDrawer] = React.useState(false);
  const [addFeatureGroupDrawer, setAddFeatureGroupDrawer] =
    React.useState(false);
  const [allChargesData, setAllChargesData] = React.useState([]);

  const [chargesDataIDs, setChargesDataIDs] = React.useState([]);

  const [addDataIDs, setAddDataIDs] = React.useState([]);
  const [addOnDrawer, setAddOnDrawer] = React.useState(false);
  const [addOnData, setAddOnData] = React.useState([]);

  const [isSaving, setIsSaving] = React.useState(false);

  const [dataList, setDataList] = React.useState({ ...initialState });

  const [allFeatureGroupData, setAllFeatureGroupData] = React.useState([]);

  const [allRuleMaster, setRuleMaster] = React.useState([]);

  const [constructedDataState, setConstructedData] = React.useState([]);

  const [planFeatureDataForEdit, setPlanFeatureDataForEdit] = React.useState(
    []
  );
  const [state1, setState1] = React.useState({
    ...initialForPerUser,
  });

  React.useEffect(() => {
    if (dataList?.user_fee_type !== "per_user") {
      setState1({ ...initialForPerUnit })
    } else {
      setState1({ ...initialForPerUser })
    }
  }, [dataList?.user_fee_type])
  const updateState1 = (key, value) => {
    let error = state1.error;
    error[key] = "";
    if (key === "property" && value === "") {
      setState1({
        ...state1,
        property: "",
        unit: "",
        community_property: "",
      });
    } else if (key === "unit" && value === "") {
      setState1({
        ...state1,
        unit: "",
        residential_unit: "",
        commercial_unit: "",
      });
    } else {
      setState1({ ...state1, [key]: value });
    }
  };

  // location state
  const { state } = useLocation();

  const validateChildCounts = () => {
    let error = state1.error;
    let isValid = true;
    if (Number(state1?.community_property) > Number(state1?.property)) {
      isValid = false;
      error.community_property =
        "Community Property limit should not greater than Property limit";
    }
    if (Number(state1?.community_property) <= Number(state1?.property)) {
      error.community_property = "";
    }
    // if (
    //   Number(state1?.residential_unit) + Number(state1?.commercial_unit) >
    //   Number(state1?.unit)
    // ) {
    //   isValid = false;
    //   error.residential_unit =
    //     "Residential unit limit and Commercial unit limit should not greater than Unit limit";
    //   error.commercial_unit =
    //     "Residential unit limit and Commercial unit limit should not greater than Unit limit";
    // }
    // if (
    //   Number(state1?.residential_unit) + Number(state1?.commercial_unit) <=
    //   Number(state1?.unit)
    // ) {
    //   error.residential_unit = "";
    //   error.commercial_unit = "";
    // }
    setState1({ ...state1, error });

    return isValid;
  };
  React.useEffect(() => {
    // getAllFeaturesGroups();
    getRuleMaster();
    // eslint-disable-next-line
  }, [!state?.isEdit]);

  React.useEffect(() => {
    const groupByCategory = allFeatureGroupData.reduce((group, feat) => {
      const { module_name } = feat;
      group[module_name] = group[module_name] ?? [];
      group[module_name].push(feat);
      return group;
    }, {});

    let constract_data = Object.keys(groupByCategory)?.map((key) => {
      return {
        data: groupByCategory?.[key]?.map((item, index) => {
          return {
            feature_group_id: item?.id,
            feature_group_name: item?.name,
            bucket_size: dataList?.size,
            module_name: index === 0 ? item?.module_name : null,
            features: item?.feature_group_feature_mappings?.map((i) => {
              return {
                feature_id: i?.feature?.id,
                feature_name: i?.feature?.name,
                control_type: null,
                threshold: null,
                is_active: i?.feature?.is_active,
                check_limit: "unlimited",
                rules: allRuleMaster?.map((e, i) => {
                  return {
                    name: e?.ruleName,
                    id: e?.id,
                    rule_master_name: e?.name,
                    check_type: e?.check_type,
                    threshold_value: e?.threshold,
                  };
                }),
              };
            }),
          };
        }),
      };
    });

    setConstructedData([...constract_data.flatMap((i) => i?.data)]);

    if (!state?.isEdit) {
      setDataList({
        ...dataList,
        plan_features: [...constract_data.flatMap((i) => i?.data)],
        load_feature: false,
      });
    }

    // eslint-disable-next-line
  }, [allFeatureGroupData, allRuleMaster]);

  React.useEffect(async () => {
    var dataForBlock;

    if (state?.isEdit & (dataList?.name === "")) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "",
      });

      let param = {
        plan_id: state?.data?.id,
      };

      await NetworkCall(
        `${config.api_rest}api/v1/plan/get`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          let main = response?.data;

          let data = main?.data;
          dataForBlock = main?.data;

          setDataList({
            ...dataList,
            ...data,
            size: data?.bucket_size,
            selected_module: data?.selected_modules,
          });
          // set charges temp Arr
          let Cdata = [];
          let CdataID = [];

          data?.plan_charges?.forEach((value) => {
            Cdata.push({
              charge_id: value?.charge_id,
              name: value?.charge?.name ? value?.charge?.name : value?.name,
              price: value?.price,
            });
            CdataID.push(value?.charge_id);
          });

          setChargesData([...Cdata]);
          setChargesDataIDs([...CdataID]);

          // set addOn temp Arr
          let adondata = [];
          let adondataID = [];

          data?.plan_addons?.forEach((value) => {
            adondata.push({
              addons_id: value?.addons_id,
              name: value?.add_on?.name ? value?.add_on?.name : value?.name,
              control_type: value?.control_type,
              monthly_price: value?.monthly_price,
              yearly_price: value?.yearly_price,
              inputText: value?.add_on?.name
                ? value?.add_on?.name
                : value?.name,
              threshold: value?.threshold,
            });

            adondataID.push(value?.addons_id);
          });

          setAddData([...adondata]);
          setAddDataIDs([...adondataID]);

          // set Feature temp Arr
          let FeatureGdata = [];
          let FeatureGdataID = [];

          data?.plan_features?.forEach((value) => {
            FeatureGdata.push(value);

            FeatureGdataID.push(value?.feature_group_id);
          });

          setAddData([...FeatureGdata]);
          setAddDataIDs([...FeatureGdataID]);

          let FeatureRulesdata = [];

          data?.plan_features?.forEach((value) => {
            FeatureGdata.push(value);
          });

          const datas = {
            company: data?.company_size,
            unit: data?.unit_size,
            resource: data?.resource_size,
            inspection: data?.instruction_item_mapping_size,
            property: data?.property_size,
            display_name: data?.display_name,
            community_property: data?.community_property_size,
            // residential_unit: data?.residential_unit_size,
            // commercial_unit: data?.commercial_unit_size,
            public_listing: data?.public_listing_size,
            private_listing: data?.private_listing_size,
            public_facility: data?.public_facility_size,
            private_facility: data?.private_facility_size,
            active_users: data?.active_users_size,
            total_area_size: data?.total_area_size,
            seat_size: data?.seat_size,
            logo: { src: data?.logo }

          };
          setState1({
            ...state1, ...datas
          });
        })
        .catch((err) => {
          console.log(err);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        });

      await NetworkCall(
        `${config.api_rest}api/v1/featuregroup/get/all`,
        NetWorkCallMethods.post,
        {
          limit: 9999999999,
          offset: 0
        },
        null,
        true,
        false
      )
        .then(() => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });

      const ids = dataForBlock?.plan_features?.map((fg) => {
        return fg?.feature_group_id;
      });

      let uniqIds = ids?.filter((x, i, a) => a?.indexOf(x) === i);

      let modifiedData = uniqIds?.map((fgId) => {
        return {
          feature_group_id: fgId,
          features: dataForBlock?.plan_features?.filter((e) => {
            return e?.feature_group_id === fgId;
          }),
        };
      });

      let getMasterData = dataForBlock?.plan_features?.map((item) => {
        return item?.feature_group_id;
      });

      let getModifiData = modifiedData?.map((item) => {
        return item?.feature_group_id;
      });

      let difference = getModifiData?.filter((x) => !getMasterData.includes(x));

      let d = [];

      // the previously existed plan from old arr in found and returned //
      let allreadyTheFeatureIsPresent = modifiedData?.map((ind) => {
        if (difference?.includes(ind?.feature_group_id)) {
          if (ind !== undefined) {
            d.push(ind);
          }
        }
      });
      // constructedData
      constructedDataState?.map((ind, masIndex) => {
        modifiedData?.map((dec, modINdex) => {
          if (ind?.feature_group_id === dec?.feature_group_id) {
            ind.features?.map((masterFeature, featureIndex) => {
              if (masterFeature?.feature_id) {
                dec?.features?.map((dsa) => {
                  if (masterFeature?.feature_id === dsa?.feature_id) {
                    constructedDataState[masIndex].features[featureIndex] = dsa;
                  }
                });
              }
            });
          }
        });
      });

      setPlanFeatureDataForEdit(constructedDataState);
    }
    // eslint-disable-next-line
  }, [state, constructedDataState]);
  // end
  console.log(state1, "//werfgfds");

  React.useEffect(() => {
    if (state?.isEdit) {
      setDataList({ ...dataList, plan_features: [...planFeatureDataForEdit] });
    }
    // eslint-disable-next-line
  }, [planFeatureDataForEdit]);

  React.useEffect(() => {
    if (addChargeDrawer === true) {
      getAllCharges();
    }
    if (addOnDrawer === true) {
      getAllAddOn();
    }
    // if (addFeatureGroupDrawer === true) {
    //   getAllFeaturesGroups();
    // }
    // eslint-disable-next-line
  }, [addChargeDrawer, addOnDrawer, addFeatureDrawer, addFeatureGroupDrawer]);

  const setSelectCharges = (e, value, key) => {
    if (e === true) {
      setChargesData([
        ...ChargesData,
        { charge_id: value?.id, name: value?.name, price: "" },
      ]);
      setChargesDataIDs([...chargesDataIDs, value.id]);
    } else {
      setChargesData(ChargesData.filter((val) => val.charge_id !== value.id));
      setChargesDataIDs(chargesDataIDs.filter((val) => val !== value.id));
    }
  };

  const setSelectAddon = (e, value, key) => {
    if (e === true) {
      setAddData([
        ...addData,
        {
          addons_id: value?.id,
          name: value?.name,
          control_type: "Limited",
          monthly_price: null,
          yearly_price: null,
          inputText: value?.name,
          threshold: null,
        },
      ]);

      setAddDataIDs([...addDataIDs, value.id]);
    } else {
      setAddData(addData.filter((val) => val.addons_id !== value.id));
      setAddDataIDs(addDataIDs.filter((val) => val !== value.id));
    }
  };

  const saveCharges = () => {
    let ChargesData1 = JSON.parse(JSON.stringify(ChargesData));
    let error = dataList.error;
    error["chargespricing"] = "";
    setDataList({ ...dataList, plan_charges: [...ChargesData1], error });
    setmapsubcription(false);
  };

  const saveAddOn = () => {
    let addOnData1 = JSON.parse(JSON.stringify(addData));
    let error = dataList.error;
    error["plan_addons"] = "";
    setDataList({ ...dataList, plan_addons: [...addOnData1], error });
    setmapsubcription(false);
  };

  const updateState = (key, value) => {
    let error = dataList.error;
    error[key] = "";
    setDataList({ ...dataList, [key]: value, error });
  };

  const setCheckedFeature = (
    checked,
    value,
    val,
    index,
    groupName,
    groupIndex
  ) => {
    // update checked state
    dataList.plan_features[groupIndex].features[index]["is_active"] = checked;
    setDataList({ ...dataList });
  };

  const featureinputChange = (
    setState,
    state,
    key,
    value,
    index,
    val,
    groupName,
    groupIndex
  ) => {
    // update checked state
    dataList.plan_features[groupIndex].features[index][key] = value;
    setDataList({ ...dataList });
  };

  const featurethresoldChange = (
    key,
    value,
    index,
    val,
    groupName,
    groupIndex
  ) => {
    val.rules[groupIndex][key] = value;
  };

  const featureChange = (key, value, val) => {
    val[key] = value;
  };

  const featureRadioChange = (
    setState,
    state,
    key,
    value,
    index,
    val,
    groupName,
    groupIndex
  ) => {
    // update radio state
    state[index].plan_feature_rules[groupIndex].rule_master_type.check_type =
      value;

    // if (value === "limit") {

    //   dataList.plan_features[groupIndex].features[index].plan_feature_rules[groupIndex].rule_master_type.check_type = null;
    // }
    setDataList({ ...dataList });
  };

  const addOnRadioChange = (setState, dataUpdate, key, value, index, val) => {
    if (value !== undefined) {
      // update state for payload
      dataList.plan_addons[index][key] = value;
      if (value === "Unlimited") {
        dataList.plan_addons[index]["threshold"] = null;
      }
      setDataList({ ...dataList });

      // update state for TempArr
      let addOnDataUpdateRadioInTempArr = addData?.find(
        (i) => i?.addons_id === val?.addons_id
      );
      addOnDataUpdateRadioInTempArr[key] = value;
      setAddData([...addData]);
    }
  };

  const inputChange = (setState, dataUpdate, key, value, index, val) => {
    // update state for payload
    dataList.plan_addons[index][key] = value;
    setDataList({ ...dataList });

    // update state for TempArr
    let addOnDataUpdateinTempArr = addData?.find(
      (i) => i?.addons_id === val?.addons_id
    );
    addOnDataUpdateinTempArr[key] = value;
    setAddData([...addData]);
  };

  const DeleteCard = (setState, dataUpdate, index) => {
    dataList.plan_addons.splice(index, 1);
    setState({ ...dataList });
  };

  // delete addOn
  const DeleteAddOn = (setState, value, index) => {
    // delete in state for payload
    dataList.plan_addons.splice(index, 1);
    setState({ ...dataList });
    // delete in TempArr
    setAddData(addData.filter((val) => val.addons_id !== value.addons_id));
    setAddDataIDs(addDataIDs.filter((val) => val !== value.addons_id));
  };

  // delete charge
  const deleteCharges = (setState, value, index) => {
    // delete in state for payload
    dataList.plan_charges.splice(index, 1);
    setState({ ...dataList });
    // delete in TempArr
    setChargesData(
      ChargesData.filter((val) => val?.charge_id !== value?.charge_id)
    );
    setChargesDataIDs(chargesDataIDs.filter((val) => val !== value?.charge_id));
  };

  // update charge
  const chargesUpdate = (value, key, val, index) => {
    // update in state for payload
    let priceData = dataList?.plan_charges?.find(
      (i) => i?.charge_id === val?.charge_id
    );
    priceData.price = value;
    setDataList({ ...dataList });
    // update in TempArr
    let priceDataUpdateinTempArr = ChargesData?.find(
      (i) => i?.charge_id === val?.charge_id
    );
    priceDataUpdateinTempArr.price = value;
    setChargesData([...ChargesData]);
  };

  // const addFeatureGroup = () => {
  //   SetAddFeatureDrawer(false);
  //   setactionCheck(false);
  //   setAddOnDrawer(false);
  //   setAddChargeDrawer(false);
  //   setAddFeatureGroupDrawer(true);
  //   setmapsubcription(!mapsubcription);
  // }

  const addCharges = () => {
    SetAddFeatureDrawer(false);
    setactionCheck(false);
    setAddOnDrawer(false);
    setAddFeatureGroupDrawer(false);
    setAddChargeDrawer(true);
    setmapsubcription(!mapsubcription);
  };

  const closeDrawer = () => {
    setmapsubcription(!mapsubcription);
  };

  // const addFeatures = async (setState, dataUpdate, sample, type) => {

  //   SetAddFeatureDrawer(true);
  //   setactionCheck(false);
  //   setAddChargeDrawer(false);
  //   setAddOnDrawer(false);

  //   setmapsubcription(!mapsubcription);

  // };

  const addAddOn = () => {
    SetAddFeatureDrawer(false);
    setactionCheck(false);
    setAddChargeDrawer(false);
    setAddFeatureGroupDrawer(false);
    setAddOnDrawer(true);
    setmapsubcription(!mapsubcription);
  };

  // Get All Charges
  const getAllCharges = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api_rest}api/v1/charge/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setAllChargesData(main?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get All FeaturesGroups
  const getAllFeaturesGroups = (modules) => {
    setDataList({ ...dataList, load_feature: true, selected_module: modules });
    NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all`,
      NetWorkCallMethods.post,
      {
        module_id: modules?.map((i) => i?.value),
        limit: 9999999999,
        offset: 0

      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data?.result;
        setAllFeatureGroupData(main);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Plans
  const getRuleMaster = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    NetworkCall(
      `${config.api_rest}api/v1/rule_master/get/all`,
      NetWorkCallMethods.get,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data?.result ?? [];
        setRuleMaster(main);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get All Addon
  const getAllAddOn = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api_rest}api/v1/addon/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setAddOnData(main?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTitleName = () => {
    if (addFeatureDrawer) {
      return "Add Features";
    }
    if (actionCheck) {
      return "add-ons";
    }
    if (addChargeDrawer) {
      return "Add Charges";
    }
    if (addOnDrawer) {
      return "Add Add-on";
    }
    if (addFeatureGroupDrawer) {
      return "Add Feature Groups";
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //handle module
  const handleModule = (value) => {
    // if (value?.length > 0) {
    //   getAllFeaturesGroups(value);
    // } else {
    //   setDataList({ ...dataList, selected_module: value, plan_features: [] });
    // }
    setDataList({ ...dataList, selected_module: value });
  };

  console.log(state);
  // save next function
  const save = (ids) => {
    if (validateChildCounts()) {
      let payload = {
        ...dataList,
        monthly_fees: dataList?.monthly_fees === "" ? undefined : dataList?.monthly_fees,
        yearly_fees: dataList?.yearly_fees === "" ? undefined : dataList?.yearly_fees,
        selected_modules: dataList?.selected_module,
        bucket_size: dataList?.size,
        plan_id: state?.data?.id ?? undefined,
        // plan_features: dataList?.plan_features?.map((i) => {
        //   return {
        //     ...i,
        //     features: i?.features?.map((j) => {
        //       return {
        //         ...j,
        //         control_type:
        //           j?.check_limit === "unlimited" ? "Unlimited" : "Limited",
        //         threshold_name: j?.rules?.[0]?.name,
        //         threshold: j?.rules?.[0]?.threshold_value,
        //         rules:
        //           j?.check_limit === "unlimited"
        //             ? []
        //             : j?.rules?.map((k) => {
        //               return {
        //                 name: k?.name,
        //                 rule_type_master_id: k?.id,
        //                 threshold: k?.threshold_value,
        //                 control_type: j?.check_limit,
        //                 threshold_name: k?.name,
        //                 check_json: {
        //                   [k?.check_type]: k?.threshold_value,
        //                 },
        //               };
        //             }),
        //       };
        //     }),
        //   };
        // }),
        company_size: state1?.company ?? null,
        property_size: state1?.property ?? null,
        unit_size: state1?.unit ?? null,
        resource_size: state1?.resource ?? null,
        instruction_item_mapping_size: state1?.inspection ?? null,
        display_name: state1?.display_name,
        community_property_size: state1?.community_property,
        residential_unit_size: 0,
        commercial_unit_size: 0,
        public_listing_size: state1?.public_listing,
        private_listing_size: state1?.private_listing,
        public_facility_size: state1?.public_facility,
        private_facility_size: state1?.private_facility,
        active_users_size: state1?.active_users,
        total_area_size: state1?.total_area_size,
        seat_size: state1?.seat_size,
        logo: state1?.logo?.src ?? undefined
      };

      NetworkCall(
        `${config.api_rest}api/v1/plan/${state?.data?.id ? `update` : `new`}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let main = response.data;
          history.push({
            pathname: Routes.plan,
          });
        })
        .catch((err) => {
          setIsSaving(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.planErr,
          });
        });
    }
  };

  const maualResponse = (array) => {
    const details = array?.data?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };

  return (
    <div>
      <TopNavBar
        title={state?.isEdit ? `Edit Plan` : `Create new plan`}
        buttonLabel={
          state?.isEdit
            ? isSaving
              ? "Editing..."
              : "Edit"
            : isSaving
              ? "saving..."
              : "Save"
        }
        functions={save}
        disabled={isSaving ? isSaving : state?.isView}
        back="/plan"
      />

      <div className={classes.root}>
        {/* basic details section */}

        <div className={classes.customerForm}>
          <Grid container spacing={2} paddingLeft={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography className={classes.basicTittle}>Plan Logo</Typography>
            </Grid>
            <Grid item xs={3}>
              <LogoUpload
                isrequired
                logo_title={""}
                handleChange={(value) => updateState1("logo", value)}
                data={state1?.logo}
                isError={state1?.logo?.error ?? false}
                errorMessage={state1?.logo?.errorMsg ?? false}
                isReadonly={state?.isView}
              />
            </Grid>
          </Grid>
          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>
              Basic Details
            </Typography>

            <InputLabel className={classes.label}>
              Plan Name<sup>*</sup>
            </InputLabel>

            <TextField
              className={classes.input}
              fullWidth
              variant="outlined"
              placeholder="Plan Name"
              size="small"
              type="text"
              value={dataList.name}
              onChange={(e) => updateState("name", e.target.value)}
              disabled={state?.isView}
            />

            <InputLabel className={classes.label}>
              Description<sup>*</sup>
            </InputLabel>

            <TextField
              className={classes.input}
              placeholder="Description"
              multiline
              fullWidth
              rows={2}
              maxRows={4}
              value={dataList.desc}
              onChange={(e) => updateState("desc", e.target.value)}
              disabled={state?.isView}
            />

            <div className={classes.checkBoxes}>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_public_portal}
                  onChange={(e) =>
                    updateState("is_public_portal", e.target.checked)
                  }
                  disabled={state?.isView}
                />
                <Typography>
                  Check to list this plan in the public portal
                </Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_recommended_plan}
                  onChange={(e) =>
                    updateState("is_recommended_plan", e.target.checked)
                  }
                  disabled={state?.isView}
                />
                <Typography>Check to make this a recommended plan</Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_metered_billing}
                  onChange={(e) =>
                    updateState("is_metered_billing", e.target.checked)
                  }
                  disabled={state?.isView}
                />
                <Typography>Check to enable metered billing</Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_active}
                  onChange={(e) => updateState("is_active", e.target.checked)}
                  disabled={state?.isView}
                />
                <Typography>Check to active this plan</Typography>
              </Box>
            </div>

            <Box marginTop="12px" />
            {/* ratio section */}
            <div>
              <FormControl>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    checked={dataList?.user_fee_type === "per_user"}
                    value={"per_user"}
                    control={<Radio />}
                    label="Per User"
                    onClick={(e) => updateState("user_fee_type", "per_user")}
                    disabled={state?.isView}
                  />

                  {/* <FormControlLabel
                    checked={dataList?.user_fee_type === "flat_fee"}
                    value={"flat_fee"}
                    control={<Radio />}
                    label="Flat fee"
                    onClick={(e) => updateState("user_fee_type", "flat_fee")}
                    disabled={state?.isView}
                  /> */}
                  <FormControlLabel
                    checked={dataList?.user_fee_type === "per_unit"}
                    value={"per_unit"}
                    control={<Radio />}
                    label="Per Unit"
                    onClick={(e) => updateState("user_fee_type", "per_unit")}
                    disabled={state?.isView}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Box marginTop="12px" />

            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Upload Size (GB)"
                    value={dataList?.size}
                    placeholder="Enter Upload Size"
                    type="number"
                    errorMessage={dataList?.error?.size}
                    isError={dataList?.error?.size?.length > 0}
                    onChange={(e) => updateState("size", e.target.value)}
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Company Limit"
                    placeholder="Company Limit"
                    type="number"
                    value={state1?.company}
                    errorMessage={state1?.error?.company}
                    isError={state1?.error?.company?.length > 0}
                    onChange={(e) => updateState1("company", e.target.value)}
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Property Limit"
                    placeholder="Property Limit"
                    type="number"
                    value={state1?.property}
                    errorMessage={state1?.error?.property}
                    isError={state1?.error?.property?.length > 0}
                    onChange={(e) => updateState1("property", e.target.value)}
                    disabled={true}
                    isReadonly={true}


                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Unit Limit"
                    placeholder="Unit Limit"
                    type="number"
                    value={state1?.unit}
                    errorMessage={state1?.error?.unit}
                    isError={state1?.error?.unit?.length > 0}
                    onChange={(e) => updateState1("unit", e.target.value)}
                    isReadonly={state?.isView || dataList?.user_fee_type === "per_user"}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Resource Limit"
                    placeholder="Resource Limit"
                    type="number"
                    value={state1?.resource}
                    errorMessage={state1?.error?.resource}
                    isError={state1?.error?.resource?.length > 0}
                    onChange={(e) => updateState1("resource", e.target.value)}
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="inspection Mapping Limit"
                    placeholder="inspection Mapping Limit"
                    type="number"
                    value={state1?.inspection}
                    errorMessage={state1?.error?.inspection}
                    isError={state1?.error?.inspection?.length > 0}
                    onChange={(e) => updateState1("inspection", e.target.value)}
                    isReadonly={state?.isView || dataList?.user_fee_type === "per_user"}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    isReadonly={dataList?.user_fee_type === "per_user" ? true : state?.isView ? true : (state1?.property?.length || Number(state1?.property)) > 0 ? false : true
                    }
                    label="Community Property Limit"
                    placeholder="Community Property Limit"
                    type="number"
                    value={state1?.community_property}
                    errorMessage={state1?.error?.community_property}
                    isError={state1?.error?.community_property?.length > 0}
                    onChange={(e) =>
                      updateState1("community_property", e.target.value)
                    }

                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    isReadonly={state1?.unit?.length > 0 ? false : true}
                    label="Residential Unit Limit"
                    placeholder="Residential Unit Limit"
                    type="number"
                    value={state1?.residential_unit}
                    errorMessage={state1?.error?.residential_unit}
                    isError={state1?.error?.residential_unit?.length > 0}
                    onChange={(e) =>
                      updateState1("residential_unit", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    isReadonly={state1?.unit?.length > 0 ? false : true}
                    label="Commercial Unit Limit"
                    placeholder="Commercial Unit Limit"
                    type="number"
                    value={state1?.commercial_unit}
                    errorMessage={state1?.error?.commercial_unit}
                    isError={state1?.error?.commercial_unit?.length > 0}
                    onChange={(e) =>
                      updateState1("commercial_unit", e.target.value)
                    }
                  />
                </Grid> */}
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Public Listing Limit"
                    placeholder="Public Listing Limit"
                    type="number"
                    value={state1?.public_listing}
                    errorMessage={state1?.error?.public_listing}
                    isError={state1?.error?.public_listing?.length > 0}
                    onChange={(e) =>
                      updateState1("public_listing", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Private Listing Limit"
                    placeholder="Private Listing Limit"
                    type="number"
                    value={state1?.private_listing}
                    errorMessage={state1?.error?.private_listing}
                    isError={state1?.error?.private_listing?.length > 0}
                    onChange={(e) =>
                      updateState1("private_listing", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Display Name"
                    placeholder="Display Name"
                    value={state1?.display_name}
                    errorMessage={state1?.error?.display_name}
                    isError={state1?.error?.display_name?.length > 0}
                    onChange={(e) =>
                      updateState1("display_name", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="No. Of active Invited users"
                    placeholder="No. Of active Invited users"
                    type="number"
                    value={state1?.active_users}
                    errorMessage={state1?.error?.active_users}
                    isError={state1?.error?.active_users?.length > 0}
                    onChange={(e) => updateState1("active_users", e.target.value)}
                    isReadonly={state?.isView || dataList?.user_fee_type === "per_unit"}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Public Facility Listing Limit"
                    placeholder="Public Facility Listing Limit"
                    type="number"
                    value={state1?.public_facility}
                    errorMessage={state1?.error?.public_facility}
                    isError={state1?.error?.public_facility?.length > 0}
                    onChange={(e) =>
                      updateState1("public_facility", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Private Facility Listing Limit"
                    placeholder="Private Facility Listing Limit"
                    type="number"
                    value={state1?.private_facility}
                    errorMessage={state1?.error?.private_facility}
                    isError={state1?.error?.private_facility?.length > 0}
                    onChange={(e) =>
                      updateState1("private_facility", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Total Area Size Limit"
                    placeholder="Total Area Size Limit"
                    type="number"
                    value={state1?.total_area_size}
                    errorMessage={state1?.error?.total_area_size}
                    isError={state1?.error?.total_area_size?.length > 0}
                    onChange={(e) =>
                      updateState1("total_area_size", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Seat Size Limit"
                    placeholder="Seat Size Limit"
                    type="number"
                    value={state1?.seat_size}
                    errorMessage={state1?.error?.seat_size}
                    isError={state1?.error?.seat_size?.length > 0}
                    onChange={(e) =>
                      updateState1("seat_size", e.target.value)
                    }
                    isReadonly={state?.isView}

                  />
                </Grid>
              </Grid>
            </Box>
          </div>

          {/* pricing section */}

          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>Pricing</Typography>

            <div className={classes.setPricing}>
              <Typography>
                Billing Pricing <sup>*</sup>
              </Typography>

              <Box className={classes.togglebox} flexGrow={1}>
                <Box
                  className={
                    dataList.billing_period === "Monthly"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  onClick={(e) => updateState("billing_period", "Monthly")}
                  style={{ "pointer-events": state?.isView ? "none" : "auto" }}
                >
                  <Typography>Monthly</Typography>
                </Box>

                <Box
                  className={
                    dataList.billing_period === "Yearly"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  style={{
                    marginLeft: "10px",
                    "pointer-events": state?.isView ? "none" : "auto",
                  }}
                  onClick={(e) => updateState("billing_period", "Yearly")}
                >
                  <Typography>Yearly</Typography>
                </Box>
              </Box>
            </div>

            {/* set pricing section */}

            <div className={classes.setPricingMain}>
              <div className={classes.setPricing}>
                <Typography>
                  Set Pricing <sup>*</sup>
                </Typography>
                <Box className={classes.pricing}>
                  <PricingInput
                    tittle={" Mountly"}
                    amount={dataList?.monthly_fees}
                    onChange={(e) =>
                      updateState("monthly_fees", e.target.value)
                    }
                    isError={dataList?.error?.monthly_fees}
                    errorMessage={"enter valid"}
                    type={"number"}
                    placeholder={"Amount..."}
                    disabled={state?.isView}
                  />
                  &nbsp;
                  <PricingInput
                    tittle={" Yearly"}
                    amount={dataList?.yearly_fees}
                    onChange={(e) => updateState("yearly_fees", e.target.value)}
                    isError={dataList?.error?.yearly_fees}
                    errorMessage={"enter valid"}
                    type={"number"}
                    placeholder={"Amount..."}
                    disabled={state?.isView}
                  />
                </Box>
              </div>


            </div>

            {/* Billing cycles */}

            <div className={classes.setPricing}>
              <Typography>
                Billing cycles <sup>*</sup>
              </Typography>

              <Box className={classes.togglebox} flexGrow={1}>
                <Box
                  className={
                    dataList.billing_cycles === "fixed"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  onClick={() => updateState("billing_cycles", "fixed")}
                  style={{ "pointer-events": state?.isView ? "none" : "auto" }}
                >
                  <Typography>Fixed</Typography>
                </Box>

                <Box
                  className={
                    dataList.billing_cycles === "forever"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  style={{
                    marginLeft: "10px",
                    "pointer-events": state?.isView ? "none" : "auto",
                  }}
                  onClick={() => updateState("billing_cycles", "forever")}
                >
                  <Typography>Forever</Typography>
                </Box>
              </Box>
            </div>
          </div>

          {/* Feature Set section */}
          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>
              Feature Set and Controls
            </Typography>

            <SelectBox
              isRequired
              label="Select Modules"
              placeholder="Select Modules"
              options={[]}
              value={dataList?.selected_module ?? ""}
              onChange={(value) => {
                handleModule(value);
              }}
              isMulti={true}
              isPaginate={true}
              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  `${config.property_automate_api}/pasm/modules`,
                  { "x-api-key": config.api_key },
                  {},
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  maualResponse
                )
              }
              isError={dataList?.error?.selected_module?.length > 0}
              errorMessage={dataList?.error?.selected_module}
            // isReadOnly={state?.isEdit}
            />
            {/* <Box height="12px" /> */}

            {/* <div className={classes.AccordionParentDiv}>
              {dataList?.plan_features?.length > 0 ? (
                dataList?.plan_features?.map((item, index) => {
                  return (
                    <>
                      {item?.module_name && (
                        <Typography className={classes.module_name}>
                          {item?.module_name}
                        </Typography>
                      )}

                      <Accordion
                        expanded={expanded === item?.feature_group_name}
                        onChange={handleChange(item?.feature_group_name)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            {item?.feature_group_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FeaturesCard
                            Editstate={state?.isEdit}
                            Acc
                            index={index}
                            groupName={item?.feature_group_name}
                            state={item?.features}
                            limited={() =>
                              updateState("collabirationlimited", "limited")
                            }
                            user={() =>
                              updateState("collabirationlimited", "unlimited")
                            }
                            ActivityCard={classes.ActivityCard}
                            isButtonNot={false}
                            isCancel={false}
                            contantcard={classes.contantcard}
                            isTittle
                            // addFeatures={addFeatures}
                            radioChange={featureRadioChange}
                            setChecked={setCheckedFeature}
                            inputChange={featureinputChange}
                            inputthresoldChange={featurethresoldChange}
                            featureChange={featureChange}
                            // updatePlanState={updatePlanState}
                            // setState={setCollabirationData}
                            DeleteCard={DeleteCard}
                            disabled={state?.isView}
                            sampleJson={{
                              subTittle: "Messege",
                              isUserInput: true,
                              inputText: "Messege",
                            }}
                            buttontext={"+ Add features"}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })
              ) : (
                <Typography className={classes.addonTittle}>
                  No Features Found
                </Typography>
              )}
            </div> */}
          </div>

          {/* Add-ons section */}

          {/* <div className={classes.sectionsaddon}>
            <Typography className={classes.addonTittle}>Add-ons</Typography>


            <AddFeaturesCard
              state={dataList?.plan_addons}
              isPrice={true}
              limited={() => updateState("limited", "limited")}
              unlimited={() => updateState("limited", "unlimited")}
              limitUser={(e) => updateState("userLimit", e.target.value)}
              disabled={state?.isView}
              isUserInput
              ActivityCard={classes.ActivityCardwithout}
              contantcard={classes.contantcardwithout}
              isTittle
              isButtonNot
              addFeatures={addAddOn}
              radioChange={addOnRadioChange}
              inputChange={inputChange}
              setState={setDataList}
              DeleteCard={DeleteAddOn}
              sampleJson={{
                subTittle: "Reports",
                isUserInput: true,
                inputText: "Reports",
                isInput: true,
              }}
              type="action"
              buttontext={"+ Add add-on"}
            />
          </div> */}

          {/* charged section */}
          {/* 
          <div className={classes.sectionscharges}>
            <div className={classes.chargesTittlemain}>
              <Typography className={classes.basicTittle}>Charges</Typography>

              <Typography className={classes.ghargeTittle}>
                <ErrorRoundedIcon
                  style={{ fontSize: 17, marginRight: 6, marginBottom: -4 }}
                />
                One - time fee, charged when subscription is actived
              </Typography>
            </div>

            {dataList?.plan_charges?.map((val, index) => {
              return (
                <div>
                  <div className={classes.chargesTittlemain1}>
                    <Typography className={classes.basicTittle}>
                      {val?.charge?.name ? val?.charge?.name : val?.name}
                    </Typography>
                    <Typography className={classes.closeIcon}>
                      <IconButton
                        disabled={state?.isView}
                        onClick={() => deleteCharges(setDataList, val, index)}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    </Typography>
                  </div>


                  <div className={classes.setPricingMain}>
                    <div className={classes.setPricing}>
                      <Typography>
                        Set Pricing <sup>*</sup>
                      </Typography>
                      <Box className={classes.pricing}>
                        <TextField
                          className={classes.inputPrams}
                          type="number"
                          tittle={" Yearly"}
                          fullWidth
                          value={val?.price}
                          disabled={state?.isView}
                          onChange={(e) =>
                            chargesUpdate(e.target.value, "price", val, index)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.inputPrams}
                                position="end"
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              );
            })}


            <div>
              {!state?.isView && (
                <Button className={classes.createButton} onClick={addCharges}>
                  + Add Charges
                </Button>
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/* DrawerComponent */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={mapsubcription}
        toggleDrawer={closeDrawer}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              {
                <Typography variant="h6" className={classes.title} noWrap>
                  {getTitleName()}
                </Typography>
              }
              <Box>
                {addChargeDrawer && (
                  <>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={() => saveCharges()}
                      className={
                        allChargesData?.length > 0 && classes.drawerButtonTittle
                      }
                      disabled={allChargesData?.length > 0 ? false : true}
                    >
                      Save
                    </Button>
                  </>
                )}
                {addOnDrawer && (
                  <>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={() => saveAddOn()}
                      className={
                        addOnData?.length > 0 && classes.drawerButtonTittle
                      }
                      disabled={addOnData?.length > 0 ? false : true}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              {addChargeDrawer && (
                <>
                  {allChargesData?.map((v) => {
                    return (
                      <Box className={classes.addonSection}>
                        <Typography>{v.name}</Typography>
                        <Checkbox
                          color="primary"
                          value={v.id}
                          onChange={(e) =>
                            setSelectCharges(e.target.checked, v, v.id)
                          }
                          checked={chargesDataIDs?.some(
                            (item) => item === v.id
                          )}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
              {addOnDrawer && (
                <>
                  {addOnData?.map((v) => {
                    return (
                      <Box className={classes.addonSection}>
                        <Typography>{v.name}</Typography>
                        <Checkbox
                          color="primary"
                          value={v.id}
                          onChange={(e) =>
                            setSelectAddon(e.target.checked, v, v.id)
                          }
                          checked={addDataIDs?.some((item) => item === v.id)}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};
export default withNavBars(PlanDetails);
