import React from "react";
import PropTypes from "prop-types";
import { PlanSelectCard } from "./planSelect";

const PlanSelectWidget = (props) => {
  let sampleJson = {
    featureParentData: [
      {
        feature_group_name: "user_management",
        id: "1234",
        features: [
          {
            feature_name: "feature_one",
            feature_id: "2345",
          },
          {
            feature_name: "feature_two",
            feature_id: "3456",
          },
        ],
      },
      {
        feature_group_name: "property_management",
        id: "4321",
        features: [
          {
            feature_name: "feature_a",
            feature_id: "23456",
          },
          {
            feature_name: "feature_b",
            feature_id: "34567",
          },
        ],
      },
      {
        feature_group_name: "Invoice_management",
        id: "3214",
        features: [
          {
            feature_name: "feature_3",
            feature_id: "234567",
          },
          {
            feature_name: "feature_4",
            feature_id: "345678",
          },
        ],
      },
      {
        feature_group_name: "Payment_management",
        id: "32145",
        features: [
          {
            feature_name: "feature_get",
            feature_id: "2345678",
          },
          {
            feature_name: "feature_post",
            feature_id: "3456789",
          },
        ],
      },
      {
        feature_group_name: "Host_management",
        id: "321456",
        features: [
          {
            feature_name: "host_up",
            feature_id: "23456789",
          },
          {
            feature_name: "host_down",
            feature_id: "34567890",
          },
        ],
      },
    ],
    plans: [
      {
        plan_name: "Basic",
        plan_id: "1111",
        color: "#78B1FE",
        amount: "1",
        description: "Simple pricing based on units and users",
        allowed_features: [
          {
            feature_group_name: "user_management",
            id: "1234",
            features: [
              {
                feature_name: "feature_one",
                feature_id: "2345",
              },
              {
                feature_name: "feature_two",
                feature_id: "3456",
              },
            ],
          },
        ],
      },
      {
        plan_name: "professional",
        plan_id: "2222",
        color: "#5AC782",
        amount: "2",
        description: "Simple pricing based on units and users",
        allowed_features: [
          {
            feature_group_name: "user_management",
            id: "1234",
            features: [
              {
                feature_name: "feature_one",
                feature_id: "2345",
              },
              {
                feature_name: "feature_two",
                feature_id: "3456",
              },
            ],
          },
          {
            feature_group_name: "Invoice_management",
            id: "3214",
            features: [
              {
                feature_name: "feature_3",
                feature_id: "234567",
              },
              {
                feature_name: "feature_4",
                feature_id: "345678",
              },
            ],
          },
        ],
      },
      {
        plan_name: "Expert",
        plan_id: "3333",
        color: "#F15A29",
        amount: "3",
        description: "Simple pricing based on units and users",
        allowed_features: [
          {
            feature_group_name: "user_management",
            id: "1234",
            features: [
              {
                feature_name: "feature_one",
                feature_id: "2345",
              },
              {
                feature_name: "feature_two",
                feature_id: "3456",
              },
            ],
          },
          {
            feature_group_name: "property_management",
            id: "4321",
            features: [
              {
                feature_name: "feature_a",
                feature_id: "23456",
              },
              {
                feature_name: "feature_b",
                feature_id: "34567",
              },
            ],
          },
          {
            feature_group_name: "Invoice_management",
            id: "3214",
            features: [
              {
                feature_name: "feature_3",
                feature_id: "234567",
              },
              {
                feature_name: "feature_4",
                feature_id: "345678",
              },
            ],
          },
          {
            feature_group_name: "Payment_management",
            id: "32145",
            features: [
              {
                feature_name: "feature_get",
                feature_id: "2345678",
              },
              {
                feature_name: "feature_post",
                feature_id: "3456789",
              },
            ],
          },
          {
            feature_group_name: "Host_management",
            id: "321456",
            features: [
              {
                feature_name: "host_up",
                feature_id: "23456789",
              },
            ],
          },
        ],
      },
      {
        plan_name: "Prime",
        plan_id: "4444",
        color: "#F1A129",
        amount: "4",
        description: "Simple pricing based on units and users",
        allowed_features: [
          {
            feature_group_name: "user_management",
            id: "1234",
            features: [
              {
                feature_name: "feature_one",
                feature_id: "2345",
              },
              {
                feature_name: "feature_two",
                feature_id: "3456",
              },
            ],
          },
          {
            feature_group_name: "property_management",
            id: "4321",
            features: [
              {
                feature_name: "feature_a",
                feature_id: "23456",
              },
              {
                feature_name: "feature_b",
                feature_id: "34567",
              },
            ],
          },
          {
            feature_group_name: "Invoice_management",
            id: "3214",
            features: [
              {
                feature_name: "feature_3",
                feature_id: "234567",
              },
              {
                feature_name: "feature_4",
                feature_id: "345678",
              },
            ],
          },
          {
            feature_group_name: "Payment_management",
            id: "32145",
            features: [
              {
                feature_name: "feature_get",
                feature_id: "2345678",
              },
              {
                feature_name: "feature_post",
                feature_id: "3456789",
              },
            ],
          },
          {
            feature_group_name: "Host_management",
            id: "321456",
            features: [
              {
                feature_name: "host_up",
                feature_id: "23456789",
              },
              {
                feature_name: "host_down",
                feature_id: "34567890",
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <div>
      <PlanSelectCard
        data={props?.data ? props?.data : sampleJson}
        planClick={props?.planClick}
      />
    </div>
  );
};

PlanSelectWidget.propTypes = {
  data: PropTypes.object,
  planClick: PropTypes.func,
};

export default PlanSelectWidget;
