import React from "react";
import { withNavBars } from "../../HOCs";
import { Features } from "./features";

class FeatureParent extends React.Component {
  render() {
    return <Features />;
  }
}

export default withNavBars(FeatureParent);
