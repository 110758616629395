export const constructEditPayload = (data) => {
  const type = [
    {
      label: "Monthly",
      value: "monthly_fees",
      price: "monthly_price",
    },
    {
      label: "Yearly",
      value: "yearly_fees",
      price: "yearly_price",
    },
  ];

  let result = {
    customers: data?.customer,
    plan: { ...data?.plan, value: data?.plan?.id, label: data?.plan?.name },
    billing_type: type?.find((i) => i?.label === data?.billing_type),
    addOn: data?.add_on?.map((val) => {
      return {
        ...val,
        label: val?.name,
        value: val?.id,
      };
    }),
    modules: data?.modules,
    db_type: "",
    region: "",
    sync: false,
    shared_db: "",
    valid_till: data?.valid_till ? new Date(data?.valid_till) : null,
    error: {
      plan: "",
      billing_type: "",
      shared_db: "",
      valid_till: "",
      modules: "",
    },
  };

  console.log(result, "result");

  return result;
};
