import { Box, Button } from "@mui/material";
import React from "react";
import { Stepper } from "../../components/breadCrumb";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { Customers, ClientDetails } from "./section";
import { MapSubScription } from "./section/mapSubscription";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { constructEditPayload } from "./utils";

const IntialState = {
  customers: {},
  plan: "",
  billing_type: "",
  addOn: [],
  db_type: "",
  region: "",
  sync: false,
  shared_db: "",
  modules: [],
  valid_till: null,
  is_shared: false,
  db_name: "",
  db_host: "",
  db_username: "",
  db_password: "",
  db_port: "",
  error: {
    plan: "",
    billing_type: "",
    shared_db: "",
    valid_till: "",
    modules: "",
    db_name: "",
    db_host: "",
    db_username: "",
    db_password: "",
    db_port: ""
  },
};

export const CreateSubscription = () => {
  const backdrop = React.useContext(BackdropContext);

  const [step, setStep] = React.useState(1);
  const [data, setData] = React.useState({ ...IntialState });
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const { state } = useLocation();

  const steps = [
    {
      label: "Choose Customers",
      step: 1,
      value: step,
    },
    {
      label: "Map subscription",
      step: 2,
      value: step,
    },
    {
      label: "Client Details",
      step: 3,
      value: step,
    },
  ];

  const updateState = (key, value, key1, value1) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, [key1]: value1 });
  };

  const next = () => {
    if (step === 2) {
      if (validateMapSub()) {
        setStep(3);
      }
    } else if (step === 1) {
      setStep(2);
    } else if (step === 3) {
      if (validateClient()) {
        insertSubscription();
      }
    }
  };

  console.log(data)

  const previous = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };

  const createSubscription = (sub_id) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message:
        "Please wait while we create a new client for you, don't close the browser tab",
    });
    const payload = {
      tenant_id:
        data?.db_type?.value === "Shared DB"
          ? data?.shared_db?.value
          : undefined,
      is_shared: data?.db_type?.value === "Shared DB" ? true : false,
      is_custom: data?.db_type?.value === "Custom DB" ? true : false,
      is_shareable: data?.db_type?.value === "Dedicated DB" ? data?.is_shared ?? false : false,
      regions: data?.region?.map((i) => i?.id),
      client_details: {
        name: data?.customers?.company_name,
        is_sync: data?.sync,
        email_id: data?.customers?.company_email_id,
        mobile_no: data?.customers?.admin_meta?.[0]?.admin_mobile?.number,
        custom_name: data?.customers?.client_details?.custom_name
          ? data?.customers?.client_details?.custom_name.replace(/\s/g, "")
          : null,
        custom_domain: data?.customers?.client_details?.custom_domain,
        mobile_no_country_code:
          data?.customers?.admin_meta?.[0]?.admin_mobile?.phoneCode,
        logo_url: data?.customers?.logo,
      },
      modules: data?.modules?.map((i) => {
        return {
          id: i?.value,
          name: i?.label,
        };
      }),
      plan: {
        plan_id: data?.plan?.id,
        is_valid: true,
        valid_till: data?.valid_till,
        subscription_id: sub_id,
      },
      admins: data?.customers?.admin_meta?.map((val) => {
        return {
          username: val?.admin_name,
          email_id: val?.admin_email_id,
          mobile_no: val?.admin_mobile?.number,
          mobile_country_code: val?.admin_mobile?.phoneCode,
        };
      }),
      company_details: {
        name: data?.customers?.company_name,
      },
      company_address: {
        door_no: data?.customers?.address_line_1,
        street_2: data?.customers?.address_line_2,
        city: data?.customers?.city,
        state: data?.customers?.state,
        website: data?.customers?.company_website,
        email_id: data?.customers?.company_email_id,
        country: data?.customers?.country,
        mobile_no: data?.customers?.admin_meta?.[0]?.admin_mobile?.number,
        mobile_no_country_code:
          data?.customers?.admin_meta?.[0]?.admin_mobile?.phoneCode,
      },
      custom_details: {
        db_name: data?.db_name,
        db_host: data?.db_host,
        db_username: data?.db_username,
        db_password: data?.db_password,
        db_port: data?.db_port
      }
    };

    const headers = { "x-api-key": config.api_key };
    NetworkCall(
      `${config.property_automate_api}/pasm/client/onboard`,
      NetWorkCallMethods.post,
      payload,
      headers,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Subscription Creatied Successfully",
        });
        history.push(Routes?.subscription);
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Subscription Creation Failed",
        });
      });
  };

  const updateSubscription = (sub_id) => {
    const payload = {
      valid_till: data?.valid_till,
      subscription_id: state?.data,
      modules: data?.modules?.map((i) => {
        return {
          id: i?.value,
          name: i?.label,
        };
      }),
      admins: data?.customers?.admin_meta,
    };

    const headers = { "x-api-key": config.api_key };

    NetworkCall(
      `${config.property_automate_api}/pasm/reset_modules`,
      NetWorkCallMethods.post,
      payload,
      headers,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Subscription Updation Successfully",
        });
        history.push(Routes?.subscription);
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Subscription Updation Failed",
        });
      });
  };

  const insertSubscription = () => {
    let insertPayload = {
      valid_till: data?.valid_till,
      customer_id: data?.customers?.id,
      email_id: data?.customers?.company_email_id,
      company_name: data?.customers?.company_name,
      mobile_no: data?.customers?.admin_meta?.[0]?.admin_mobile?.number,
      plan_id: data?.plan?.id,
      billing_type: data?.billing_type?.label,
      addons: data?.addOn?.map((i) => i?.id),
      modules: data?.modules?.map((i) => {
        return {
          value: i?.value,
          label: i?.label,
        };
      }),
      plan_amount: data?.plan?.[data?.billing_type?.value],
      addon_amount: data?.addOn
        ?.map((i) => i?.[data?.billing_type?.price])
        ?.reduce((a, b) => a + b, 0),
      total_amount: isNaN(
        data?.plan?.[data?.billing_type?.value] +
          data?.addOn
            ?.map((i) => i?.[data?.billing_type?.price])
            ?.reduce((a, b) => a + b, 0)
      )
        ? 0
        : data?.plan?.[data?.billing_type?.value] +
          data?.addOn
            ?.map((i) => i?.[data?.billing_type?.price])
            ?.reduce((a, b) => a + b, 0),
    };

    NetworkCall(
      `${config.api_rest}api/v1/subscription/new`,
      NetWorkCallMethods.post,
      insertPayload,
      null,
      true,
      false
    )
      .then((res) => {
        createSubscription(res?.data?.subscription_id);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Subscription Creation Failed",
        });
      });
  };

  const validateMapSub = () => {
    let isValid = true;
    let error = data.error;
    //validate plan
    if (data?.plan?.length === 0) {
      isValid = false;
      error.plan = "Plan is Required";
    }
    //validate email
    if (data?.billing_type?.length === 0) {
      isValid = false;
      error.billing_type = "Billing Type is Required";
    }

    //validate valid till
    if (!data?.valid_till) {
      isValid = false;
      error.valid_till = "Valid Till is Required";
    }

    //validate valid module
    if (data?.modules?.length === 0) {
      isValid = false;
      error.modules = "Modules is Required";
    }

    setData({ ...data, error });
    return isValid;
  };

  const validateClient = () => {
    let isValid = true;
    let error = data.error;
    //validate plan
    if (data?.db_type?.length === 0) {
      isValid = false;
      error.db_type = "Data Type is Required";
    }
    //validate email
    if (data?.region?.length === 0) {
      isValid = false;
      error.region = "Region is Required";
    }

    if (data?.db_type?.value === "Shared DB") {
      if (data?.shared_db?.length === 0) {
        isValid = false;
        error.shared_db = "Data Base is Required";
      }
    }

    if(data?.db_type?.value === "Custom DB"){
      if(data?.db_name?.length === 0){
        isValid = false;
        error.db_name = "DB Name is Required"
      }
      if(data?.db_host?.length === 0){
        isValid = false;
        error.db_host = "DB Host is Required"
      }
      if(data?.db_username?.length === 0){
        isValid = false;
        error.db_username = "DB Username is Required"
      }
      if(data?.db_password?.length === 0){
        isValid = false;
        error.db_password = "DB Password is Required"
      }
      if(data?.db_port?.length === 0){
        isValid = false;
        error.db_port = "DB Port is Required"
      }
    }

    setData({ ...data, error });
    return isValid;
  };
  const components = {
    1: <Customers updateState={updateState} data={data} next={next} />,

    2: (
      <MapSubScription
        updateState={updateState}
        data={data}
        next={next}
        isEdit={state?.isEdit}
        validateMapSub={validateMapSub}
      />
    ),
    3: (
      <ClientDetails
        updateState={updateState}
        data={data}
        next={next}
        validateMapSub={validateMapSub}
      />
    ),
  };
  const editComponent = {
    2: (
      <MapSubScription
        updateState={updateState}
        data={data}
        isEdit={state?.isEdit}
        next={next}
        validateMapSub={validateMapSub}
      />
    ),
  };

  const onEdit = () => {
    let insertPayload = {
      valid_till: data?.valid_till,
      addons: data?.addOn?.map((i) => i?.id),
      plan_amount: data?.plan?.[data?.billing_type?.value],
      subscription_id: state?.data,
      plan_id: data?.plan?.id,
      modules: data?.modules,
    };

    NetworkCall(
      `${config.api_rest}api/v1/subscription/new`,
      NetWorkCallMethods.post,
      insertPayload,
      null,
      true,
      false
    )
      .then((res) => {
        updateSubscription();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Subscription Updated Failed",
        });
      });
  };

  React.useEffect(() => {
    if (state?.isEdit) {
      NetworkCall(
        `${config.api_rest}api/v1/subscription/get`,
        NetWorkCallMethods.post,
        {
          subscription_id: state?.data,
        },
        null,
        true,
        false
      )
        .then((response) => {
          setStep(2);
          let result = constructEditPayload(response?.data?.data);
          setData(result);
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <Box>
      <TopNavBar
        title={`${state?.isEdit ? "Edit" : "Create"} Subscription`}
        back="/subscription"
      />

      {!state?.isEdit && <Stepper steps={steps} />}
      <Box p={2}>{state?.isEdit ? editComponent[step] : components[step]} </Box>
      {state?.isEdit ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ backgroundColor: "white", padding: "12px" }}
        >
          <Button variant="contained" onClick={onEdit}>
            Edit
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ backgroundColor: "white", padding: "12px" }}
        >
          <Button variant="outlined" onClick={previous}>
            Previous
          </Button>

          <Button variant="contained" onClick={next}>
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};
