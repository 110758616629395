import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const isStstus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? "#3f51b5" : "#efbb5aeb",
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};

export const FeturetableJson = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "id", headerName: "ID", width: 350 },
      { field: "name", headerName: "Charges", width: 150 },
      {
        sortable: false,
        field: "is_active",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          return isStstus(params.value, params);
        },
      },
      {
        field: "feature_ids",
        headerName: "Features",
        width: 150,
        renderCell: (params) => {
          return `${params?.value?.length} Features`;
        },
      },
      {
        field: "updated_at",
        headerName: "Modified on",
        width: 200,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <ModeEditIcon
                onClick={(e) => onEdit(params.row, params, e)}
                style={{ cursor: "pointer", color: "#00000073" }}
              />
              {/* <DeleteForeverIcon onClick={()=>onDelete(params.value, params)} style={{cursor:"pointer",color: "#00000073"}}/> */}
            </div>
          );
        },
      },
    ],

    //  table dummy datas
    TableData: [],
  };
};
