import React from "react";
import { Configure } from "./configure";
import { withNavBars } from "../../HOCs";

class configParent extends React.Component {
  render() {
    return <Configure />;
  }
}

export default withNavBars(configParent);
