import React from "react";
import {
  TableComponent,
  TopNavBar,
  DrawerComponent,
  TextBox,
  SelectBox,
} from "../../components";
import { AddontableJson } from "./common";
import { useStylesAddon } from "./style";
import {
  Typography,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, Message } from "../../utils";
import { config } from "../../config";
import { BackdropContext, AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { useTranslation } from "react-i18next";
import { UseDebounce } from "../../components/customHooks/useDebounce";
import { loadOptionsApis } from "../../utils/asyncLoading";
import { LogoUpload } from "../../components/LogoUpload";

let IntialState = {
  name: "",
  description: "",
  group: "",
  features: "",
  is_active: false,
  bucket_size: 0,
  property_size: 0,
  unit_size: 0,
  community_property_size: 0,
  commercial_unit_size: 0,
  residential_unit_size: 0,
  company_size: 0,
  resource_size: 0,
  instruction_item_mapping_size: 0,
  public_listing_size: 0,
  private_listing_size: 0,
  active_users: 0,
  public_facility_size: 0,
  private_facility_size: 0,
  total_area_size: 0,
  seat_size: 0,
  is_system_addon: false,
  is_integration: false,
  addon_category_id: "",
  addon_sub_category_id: "",
  error: {
    name: "",
    description: "",
    group: "",
    features: "",
    bucket_size: "",
    property_size: "",
    unit_size: "",
    community_property_size: "",
    commercial_unit_size: "",
    residential_unit_size: "",
    company_size: "",
    resource_size: "",
    inspection: "",
    public_listing_size: "",
    private_listing_size: "",
    active_users: "",
    public_facility_size: "",
    private_facility_size: "",
    total_area_size: "",
    seat_size: ""
  },
};

export const AddonsNew = (props) => {
  // use classes
  const classes = useStylesAddon(props);

  const { t } = useTranslation(["topBar"]);

  // end
  const [data, setData] = React.useState({ ...IntialState });

  const [tData, setTdata] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [tempData, setTempData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [dropData, setDropData] = React.useState({
    group: [],
    single: [],
  });

  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });
  //   drawer
  const [open, setOpen] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const updateState = async (key, value, label) => {
    setData({ ...data, [key]: value });
  };

  const isIamValid = () => {
    let isValid = true;
    let error = data.error;

    if (data.name === "") {
      isValid = false;
      error.name = "Enter Name";
    }

    if (data.description === "") {
      isValid = false;
      error.description = "Enter Description";
    }
    setData({ ...data, error });
    return isValid;
  };

  // on Edit
  const onEdit = async (cell, param, e) => {
    if (e) {
      e.stopPropagation();
    }
    setData({
      name: param?.row?.name,
      description: param?.row?.desc,
      is_active: param?.row?.is_active,
      bucket_size: param?.row?.bucket_size,
      property_size: param?.row?.property_size,
      unit_size: param?.row?.unit_size,
      company_size: param?.row?.company_size,
      community_property_size: param?.row?.community_property_size,
      // residential_unit_size: param?.row?.residential_unit_size,
      // commercial_unit_size: param?.row?.commercial_unit_size,
      resource_size: param?.row?.resource_size,
      instruction_item_mapping_size: param?.row?.instruction_item_mapping_size,
      public_listing_size: param?.row?.public_listing_size,
      private_listing_size: param?.row?.private_listing_size,
      public_facility_size: param?.row?.public_facility_size,
      private_facility_size: param?.row?.private_facility_size,
      active_users: param?.row?.active_users_size,
      total_area_size: param?.row?.total_area_size,
      seat_size: param?.row?.seat_size,
      is_system_addon: param?.row?.is_system_addon ?? false,
      is_integration: param?.row?.is_integration ?? false,
      addon_category_id: { value: param?.row?.addon_category?.id, label: param?.row?.addon_category?.name },
      addon_sub_category_id: { value: param?.row?.addon_sub_category?.id, label: param?.row?.addon_sub_category?.name },
      logo: { src: param?.row?.logo }
    });

    setOpen(true);
    setEdit({
      ...edit,
      isEdit: true,
      isView: e ? false : true,
      id: param?.row?.id,
    });
  };

  // Save
  const SaveNew = () => {
    if (isIamValid()) {
      let param;
      {
        edit.isEdit
          ? (param = {
              addon_id: edit?.id,
              name: data.name,
              desc: data.description,
              bucket_size: data?.bucket_size ?? undefined,
              property_size: data?.property_size ?? undefined,
              unit_size: data?.unit_size ?? undefined,
              company_size: data?.company_size ?? undefined,
              community_property_size:
                data?.community_property_size ?? undefined,
              // residential_unit_size: data?.residential_unit_size ?? undefined,
              // commercial_unit_size: data?.commercial_unit_size ?? undefined,
              resource_size: data?.resource_size ?? undefined,
              instruction_item_mapping_size:
                data?.instruction_item_mapping_size ?? undefined,
              public_listing_size: data?.public_listing_size ?? undefined,
              private_listing_size: data?.private_listing_size ?? undefined,
              private_facility_size: data?.private_facility_size ?? undefined,
              public_facility_size: data?.public_facility_size ?? undefined,
              active_users_size: data?.active_users ?? undefined,
              total_area_size: data?.total_area_size ?? undefined,
              seat_size: data?.seat_size ?? undefined,
              addon_category_id: data?.addon_category_id?.value,
              addon_sub_category_id: data?.addon_sub_category_id?.value,
              is_system_addon: data?.is_system_addon,
              is_integration: data?.is_integration,
              logo: data?.logo?.src ?? undefined,
            })
          : (param = {
              name: data.name,
              desc: data.description,
              bucket_size: data?.bucket_size ?? undefined,
              property_size: data?.property_size ?? undefined,
              unit_size: data?.unit_size ?? undefined,
              company_size: data?.company_size ?? undefined,
              community_property_size:
                data?.community_property_size ?? undefined,
              // residential_unit_size: data?.residential_unit_size ?? undefined,
              // commercial_unit_size: data?.commercial_unit_size ?? undefined,
              resource_size: data?.resource_size ?? undefined,
              instruction_item_mapping_size:
                data?.instruction_item_mapping_size ?? undefined,
              public_listing_size: data?.public_listing_size ?? undefined,
              private_listing_size: data?.private_listing_size ?? undefined,
              private_facility_size: data?.private_facility_size ?? undefined,
              public_facility_size: data?.public_facility_size ?? undefined,
              active_users_size: data?.active_users ?? undefined,
              total_area_size: data?.total_area_size ?? undefined,
              seat_size: data?.seat_size ?? undefined,
              addon_category_id: data?.addon_category_id?.value,
              addon_sub_category_id: data?.addon_sub_category_id?.value,
              is_system_addon: data?.is_system_addon,
              is_integration: data?.is_integration,
              logo: data?.logo?.src ?? undefined,
            });
      }
      setLoading(true);
      NetworkCall(
        `${config.api_rest}${edit.isEdit ? "api/v1/addon/update" : "api/v1/addon/new"
        }`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          setLoading(false);
          let main = response.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });

          setOpen(false);
          getAll("", switchActive, 0);
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          setLoading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.addOnErr,
          });
        });
    } else {
      return false;
    }
  };

  const closeDrawer = () => {
    setData({ ...IntialState });
    setOpen(false);
  };

  const openDrawer = () => {
    setData({ ...IntialState });
    setEdit({ ...edit, isEdit: false, isView: false });
    setOpen(true);
  };

  // All Feature
  React.useEffect(() => {
    getFeature();
    // eslint-disable-next-line
  }, []);

  // Get All
  const getAll = (search, filter, offset) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter,
      limit: pageSize,
      offset: offset,
    };

    NetworkCall(
      `${config.api_rest}api/v1/addon/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setTdata(main);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };

  // Get Feature Group
  const getFeature = () => {
    NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all/active`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let arr = main?.result.map((key) => {
          let obj = {};
          obj.label = key.name;
          obj.value = key.id;
          return obj;
        });
        setDropData({ ...dropData, group: arr });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowclick = (param) => {
    if (!param?.row?.is_system_addon) {
      onEdit("", param);
    }
  };


  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getAll("", switchActive, offset)
  };


  const onAddOnSearch = (search = "") => {
    setSearchkey(search)
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAll(e, switchActive, 0);

  };
  React.useEffect(() => {
    getAll(searchkey, switchActive, 0);
    // eslint-disable-next-line
  }, [switchActive, pageSize]);
  const maualResponse = (data) => {
    let result = data?.rows?.map((i) => {
      return {
        label: i?.name,
        value: i?.id
      }
    })
    return result
  }
  return (
    <div>
      <TopNavBar
        title={t("add_ons")}
        isSearch
        buttonLabel="Create Add-on"
        isFilter
        functions={openDrawer}
        onChangeSearch={onAddOnSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      <div className={classes.root}>
        <TableComponent
          dataList={tData?.rows ?? []}
          Header={AddontableJson(onEdit)}
          cellClick={(rowData) => handleRowclick(rowData)}
          totalCount={tData?.count}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => handlePagination(newPage)}
        />
      </div>
      {/* drawer */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={open}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.title} noWrap>
                {edit?.isEdit ? "Edit add-on" : " Create new add-on"}
              </Typography>
              <Box>
                {!edit.isView && (
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={SaveNew}
                    className={classes.drawerButtonTittle}
                    disabled={loading}
                  >
                    {loading ? "saving..." : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.basicTittle}>Addon Logo</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LogoUpload
                    isrequired
                    logo_title={""}
                    handleChange={(value) => updateState("logo", value)}
                    data={data?.logo}
                    isError={data?.logo?.error ?? false}
                    errorMessage={data?.logo?.errorMsg ?? false}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    value={data?.name}
                    type="text"
                    label="Add on Name"
                    placeholder="Enter  Add on Name"
                    errorMessage={data?.error?.name}
                    isError={data?.error?.name?.length > 0}
                    onChange={(e) => updateState("name", e.target.value)}
                    isReadonly={data?.is_system_addon && edit?.isEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Description"
                    placeholder="Enter Description"
                    type="text"
                    multiline
                    value={data?.description}
                    errorMessage={data?.error?.description}
                    isError={data?.error?.description?.length > 0}
                    onChange={(e) => updateState("description", e.target.value)}
                    isReadonly={data?.is_system_addon && edit?.isEdit}

                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Switch
                      checked={data?.is_system_addon}
                      disabled={data?.is_system_addon && edit?.isEdit}
                      onChange={(e) => updateState("is_system_addon", !data?.is_system_addon)}
                      label="System Add on"
                      color="secondary"
                    />} label="System Add on" />

                  </FormGroup>


                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Switch
                      disabled={data?.is_integration && edit?.isEdit}
                      checked={data?.is_integration}
                      onChange={(e) => updateState("is_integration", !data?.is_integration)}
                      color="secondary"
                    />} label="Integration" />

                  </FormGroup>


                </Grid>
                {data?.is_system_addon &&
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <SelectBox
                        label="Addon Category"
                        placeholder="Select Addon Category"
                        value={data?.addon_category_id ?? ""}
                        onChange={(value) => {
                          updateState("addon_category_id", value);
                        }}
                        isPaginate={true}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            `${config.api_rest}api/v1/addon_category/get/all`,
                            false,
                            {},
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            maualResponse
                          )
                        }
                        isError={data?.error?.addon_category_id?.length > 0}
                        errorMessage={data?.error?.addon_category_id}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <SelectBox
                        label="Addon Sub Category"
                        placeholder="Select Addon Sub Category"
                        value={data?.addon_sub_category_id ?? ""}
                        onChange={(value) => {
                          updateState("addon_sub_category_id", value);
                        }}
                        isPaginate={true}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            `${config.api_rest}api/v1/addon_sub_category/get/all`,
                            false,
                            {},
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            maualResponse
                          )
                        }
                        isError={data?.error?.addon_sub_category_id?.length > 0}
                        errorMessage={data?.error?.addon_sub_category_id}
                      />
                    </Grid>
                  </>
                }

                {!data?.is_system_addon &&
                  <>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        value={data?.bucket_size}
                        label="Upload Size (GB)"
                        placeholder="Enter Upload Size"
                        type="number"
                        errorMessage={data?.error?.bucket_size}
                        isError={data?.error?.bucket_size?.length > 0}
                        onChange={(e) => updateState("bucket_size", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Company Limit"
                        placeholder="Company Limit"
                        type="number"
                        value={data?.company_size}
                        errorMessage={data?.error?.company_size}
                        isError={data?.error?.company_size?.length > 0}
                        onChange={(e) =>
                          updateState("company_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Property Limit"
                        placeholder="Property Limit"
                        type="number"
                        value={data?.property_size}
                        errorMessage={data?.error?.property_size}
                        isError={data?.error?.property_size?.length > 0}
                        onChange={(e) =>
                          updateState("property_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Unit Limit"
                        placeholder="Unit Limit"
                        type="number"
                        value={data?.unit_size}
                        errorMessage={data?.error?.unit_size}
                        isError={data?.error?.unit_size?.length > 0}
                        onChange={(e) => updateState("unit_size", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Resource Limit"
                        placeholder="Resource Limit"
                        type="number"
                        value={data?.resource_size}
                        errorMessage={data?.error?.resource_size}
                        isError={data?.error?.resource_size?.length > 0}
                        onChange={(e) =>
                          updateState("resource_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="inspection Mapping Limit"
                        placeholder="inspection Mapping Limit"
                        type="number"
                        value={data?.instruction_item_mapping_size}
                        errorMessage={data?.error?.instruction_item_mapping_size}
                        isError={
                          data?.error?.instruction_item_mapping_size?.length > 0
                        }
                        onChange={(e) =>
                          updateState(
                            "instruction_item_mapping_size",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        // isReadonly={
                        //   data?.property_size?.length > 0 ? false : true
                        // }
                        label="Community Property Limit"
                        placeholder="Community Property Limit"
                        type="number"
                        value={data?.community_property_size}
                        errorMessage={data?.error?.community_property_size}
                        isError={data?.error?.community_property_size?.length > 0}
                        onChange={(e) =>
                          updateState("community_property_size", e.target.value)
                        }
                      />
                    </Grid>
                    {/* commented these lines don't delete */}
                    {/* <Grid item xs={6}>
                  <TextBox
                    fullWidth={true}
                    isReadonly={data?.unit_size?.length > 0 ? false : true}
                    label="Residential Unit Limit"
                    placeholder="Residential Unit Limit"
                    type="number"
                    value={data?.residential_unit_size}
                    errorMessage={data?.error?.residential_unit_size}
                    isError={data?.error?.residential_unit_size?.length > 0}
                    onChange={(e) =>
                      updateState("residential_unit_size", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    fullWidth={true}
                    isReadonly={data?.unit_size?.length > 0 ? false : true}
                    label="Commercial Unit Limit"
                    placeholder="Commercial Unit Limit"
                    type="number"
                    value={data?.commercial_unit_size}
                    errorMessage={data?.error?.commercial_unit_size}
                    isError={data?.error?.commercial_unit_size?.length > 0}
                    onChange={(e) =>
                      updateState("commercial_unit_size", e.target.value)
                    }
                  />
                </Grid> */}
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Public Listing Limit"
                        placeholder="Public Listing Limit"
                        type="number"
                        value={data?.public_listing_size}
                        errorMessage={data?.error?.public_listing_size}
                        isError={data?.error?.public_listing_size?.length > 0}
                        onChange={(e) =>
                          updateState("public_listing_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Private Listing Limit"
                        placeholder="Private Listing Limit"
                        type="number"
                        value={data?.private_listing_size}
                        errorMessage={data?.error?.private_listing_size}
                        isError={data?.error?.private_listing_size?.length > 0}
                        onChange={(e) =>
                          updateState("private_listing_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="No. Of active Invited users"
                        placeholder="No. Of active Invited users"
                        type="number"
                        value={data?.active_users}
                        errorMessage={data?.error?.active_users}
                        isError={data?.error?.active_users?.length > 0}
                        onChange={(e) =>
                          updateState("active_users", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Public Facility Listing Limit"
                        placeholder="Public Facility Listing Limit"
                        type="number"
                        value={data?.public_facility_size}
                        errorMessage={data?.error?.public_facility_size}
                        isError={data?.error?.public_facility_size?.length > 0}
                        onChange={(e) =>
                          updateState("public_facility_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Private Facility Listing Limit"
                        placeholder="Private Facility Listing Limit"
                        type="number"
                        value={data?.private_facility_size}
                        errorMessage={data?.error?.private_facility_size}
                        isError={data?.error?.private_facility_size?.length > 0}
                        onChange={(e) =>
                          updateState("private_facility_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Total Area Size Limit"
                        placeholder="Total Area Size Limit"
                        type="number"
                        value={data?.total_area_size}
                        errorMessage={data?.error?.total_area_size}
                        isError={data?.error?.total_area_size?.length > 0}
                        onChange={(e) =>
                          updateState("total_area_size", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        fullWidth={true}
                        label="Seat Size Limit"
                        placeholder="Seat Size Limit"
                        type="number"
                        value={data?.seat_size}
                        errorMessage={data?.error?.seat_size}
                        isError={data?.error?.seat_size?.length > 0}
                        onChange={(e) =>
                          updateState("seat_size", e.target.value)
                        }
                      />
                    </Grid>
                  </>
                }
              </Grid>
            </div>
          </div>
        }
      />
    </div>
  );
};
