import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    item_card_root: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "12px",
    },
    image_div: {
        height: "48px",
        width: "48px",
        padding: "4px",
        boxShadow: "0px 3px 6px #00000014",
    },
    uploaded_image: {
        height: "40px",
        width: "40px",
        objectFit: "contain",
    },
    item_card_label: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        fontSize: "14px",
        color: theme.typography.color.secondary,
    },
    edit_update_button: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        border: `1px solid "#F2F4F7"`,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    edit_update_text: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        fontSize: "14px",
        color: "#5078E1",
    },
    cancel_button: {
        border: `1px solid #5078E1`,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    cancel_text: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        fontSize: "14px",
        color: "#5078E1"
    },
    save_button: {
        border: `1px solid #E4E8EE`,
        backgroundColor: theme.palette.background.button_background_1,
        "&:hover": {
            backgroundColor: theme.palette.background.button_background_1,
        }
    },
    save_text: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        fontSize: "14px",
        color: theme.typography.color.white,
    },
    selected_image_section_div: {
        paddingTop: "16px",
        borderTop: "1px solid #E4E8EE",
    },
    selected_image_div: {
        height: "68px",
        width: "68px",
        padding: "4px",
        boxShadow: "0px 3px 6px #00000014",
    },
    selected_image: {
        height: "60px",
        width: "60px",
        objectFit: "contain",
    },
    clear_button: {
        cursor: "pointer",
    },
    retry_button: {
        cursor: "pointer",
    },
    delete_button: {
        cursor: "pointer",
    },
}));