import makeStyles from "@mui/styles/makeStyles";
export const SubscriptionsUseStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
    },
    tab_bar_div: {
        padding: "0px 20px 0px 20px"
    },
}));


export const ConfigurationUseStyles = makeStyles((theme) => ({
    tab_content_div: {
        padding: "20px",
        backgroundColor: theme.palette.background.secondary,
        overflow: "hidden",
    },
    tab_content_inner_div: {
        height: ({ size }) => size?.height - 100,
        overflow: "overlay",
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        padding: "24px",
    },
    exploreMetaForm: {
        backgroundColor: theme.palette.background.tertiary1,
    },
    selectedButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: "12px",
        fontFamily: "crayond_semiBold",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    unSelectedButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.primary.main,
        fontSize: "12px",
        fontFamily: "crayond_semiBold",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white ",
        },
    },
    exploreMetaFormCancelButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.primary.main,
        fontSize: "14px",
        fontFamily: "crayond_semiBold",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white ",
        },
    },
    exploreMetaFormSaveButton: {
        padding: "8px 16px",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: "14px",
        fontFamily: "crayond_semiBold",
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    selected_image_section_div: {
        paddingTop: "16px",
        borderTop: "1px solid #E4E8EE",
    },
    selected_image_div: {
        height: "68px",
        width: "68px",
        padding: "4px",
        boxShadow: "0px 3px 6px #00000014",
    },
    selected_image: {
        height: "60px",
        width: "60px",
        objectFit: "contain",
    },
    item_card_label: {
        fontFamily: "crayond_bold",
        fontSize: "14px",
        color: theme.typography.color.secondary,
    },
    delete_button: {
        cursor: "pointer",
    },
}));