import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CunstomertableJson } from "./common";
// import { Typography, Button, Modal, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { TopNavBar, TableComponent } from "../../components";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
// import { AlertProps } from "../../utils";
import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { UseDebounce } from "../../components/customHooks/useDebounce";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
}));
// end

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   borderRadius: "12px",
//   boxShadow: 24,
//   p: 2,
// };

export const Customers = (props) => {
  // use classes
  const classes = useStyles();
  const history = useHistory();
  // end

  const { t } = useTranslation(["topBar"]);

  const backdrop = React.useContext(BackdropContext);
  const [pageSize, setPageSize] = React.useState(10);
  const [action, setAction] = React.useState(false);
  const [tData, setTdata] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const showAction = (action) => {
    setAction(action.length > 0 ? true : false);
  };

  // go to customer details page
  const isPushCustomerDetailPage = () => {
    history.push(Routes.customersdetails);
  };

  // All Customer
  React.useEffect(() => {
    getAll("", switchActive, 0);
    // eslint-disable-next-line
  }, [switchActive, pageSize]);

  const getAll = (search, filter, offset) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    let params = {
      search: search ? search : "",
      filter: filter,
      limit: pageSize,
      offset: offset,
    };

    NetworkCall(
      `${config.api_rest}api/v1/customer/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setTdata(main?.data);
        // if (search?.length === 2) {
        //   setTempData(main?.data?.rows);
        // }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });

  };

  // on Edit
  const onEdit = (cell, param, e) => {
    e.stopPropagation();
    history.push({
      pathname: Routes.customersdetails,
      state: {
        isEdit: true,
        data: param.row.id,
      },
    });
  };

  const handleRowclick = (param) => {
    history.push({
      pathname: Routes.customersdetails,
      state: {
        isView: true,
        data: param.row.id,
      },
    });
  };
  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getAll("", switchActive, offset)
  };


  const onCustomerSearch = (search = "") => {


    setSearchkey(search)
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAll(e, switchActive, 0);

  };
  return (
    <div>
      {/* Topbar bar */}

      <TopNavBar
        title={t("customers")}
        isSearch
        buttonLabel="Create New"
        functions={isPushCustomerDetailPage}
        action={action}
        onChangeSearch={onCustomerSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      {/* end */}

      {/* content */}

      <div className={classes.root}>
        <TableComponent
          dataList={tData?.rows ?? []}
          Header={CunstomertableJson(onEdit)}
          isChecked
          page={page}
          pageSize={pageSize}
          totalCount={tData?.count}
          setPageSize={setPageSize}
          showAction={showAction}
          cellClick={(rowData) => handleRowclick(rowData)}
          onPageChange={(newPage) => handlePagination(newPage)}
        />
        {/* end */}
      </div>
    </div>
  );
};
