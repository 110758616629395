import React from "react";
import {
  TableComponent,
  TopNavBar,
  DrawerComponent,
  SelectBox,
} from "../../components";
import { FeturetableJson } from "./common";
import { useStylesCharges } from "./style";
import {
  Typography,
  Box,
  Button,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { BackdropContext, AlertContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import { useTranslation } from "react-i18next";
import { loadOptionsApis } from "../../utils/asyncLoading";
import { UseDebounce } from "../../components/customHooks/useDebounce";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
};

const IntialState = {
  name: "",
  description: "",
  MData: [],
  module: [],
  is_active: false,
  error: {
    name: "",
    description: "",
    module: "",
  },
};

export const FeatureGroup = (props) => {
  const classes = useStylesCharges(props);

  const { t } = useTranslation(["topBar"]);

  // end
  const [data, setData] = React.useState({ ...IntialState });

  const [tempData, setTempData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const updateState = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const [tData, setTdata] = React.useState([]);
  const [fData, setFdata] = React.useState([]);
  //   drawer
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });

  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const isIamValid = () => {
    let isValid = true;
    let error = data.error;

    if (data.name === "") {
      isValid = false;
      error.name = "Enter Name";
    }

    if (data.description === "") {
      isValid = false;
      error.description = "Enter Description";
    }

    if (data.module?.length === 0) {
      isValid = false;
      error.module = "Select Module";
    }

    setData({ ...data, error });
    return isValid;
  };

  // Save
  const SaveNew = () => {
    if (isIamValid()) {
      let param;
      {
        edit.isEdit
          ? (param = {
            feature_group_id: edit?.id,
            name: data.name,
            desc: data.description,
            feature_ids: data.MData,
            is_active: data.is_active,
            module_id: data?.module?.value,
            module_name: data?.module?.label,
          })
          : (param = {
            name: data.name,
            desc: data.description,
            feature_ids: data.MData,
            module_id: data?.module?.value,
            module_name: data?.module?.label,
          });
      }
      setLoading(true);
      NetworkCall(
        `${config.api_rest}${edit.isEdit ? "api/v1/featuregroup/update" : "api/v1/featuregroup/new"
        }`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          setLoading(false);
          let main = response.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });

          setOpen(false);
          getAll();
        })
        .catch((err) => {
          setOpen(false);
          setLoading(false);
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: Message.featureGroupErr,
          });
        });
    } else {
      return false;
    }
  };

  // on Edit
  const onEdit = (cell, param, e) => {
    e.stopPropagation();
    setData({
      ...data,
      name: param?.row?.name,
      description: param?.row?.desc,
      MData: param?.row?.feature_ids,
      is_active: param?.row?.is_active,
      module: {
        label: param?.row?.module_name,
        value: param?.row?.module_id,
      },
    });
    setOpen(true);
    setEdit({
      ...edit,
      isEdit: true,
      isView: false,
      id: param?.row?.id,
    });
  };

  const closeDrawer = () => {
    setData({ ...IntialState });
    setOpen(false);
  };

  const openDrawer = () => {
    setData({ ...IntialState });
    setEdit({ ...edit, isEdit: false, isView: false });
    setOpen(true);
  };

  // All Feature
  React.useEffect(() => {
    getAll();
    getFeature("", 0);
    // eslint-disable-next-line
  }, []);

  // Get Feature
  const getFeature = (search, offset) => {
    let params = {
      search: search,
      limit: 999999,
      offset: offset
    }
    NetworkCall(
      `${config.api_rest}api/v1/feature/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setFdata(main?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get All
  const getAll = (search, filter, offset) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter,
      limit: pageSize,
      offset: offset
    };

    NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setTdata(main);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFeatureChange = (e, node) => {
    const first = node.id;
    if (data.MData.includes(first)) {
      let unchecked = [...data.MData];
      for (var i = 0; i < unchecked.length; i++) {
        if (unchecked[i] === first) {
          unchecked.splice(i, 1);
        }
      }
      setData({ ...data, MData: unchecked });
    } else {
      let checked = [...data.MData];
      checked.push(first);
      setData({ ...data, MData: checked });
    }
  };

  const handleRowclick = (param) => {
    setData({
      name: param?.row?.name,
      description: param?.row?.desc,
      MData: param?.row?.feature_ids,
      is_active: param?.row?.is_active,
    });
    setOpen(true);
    setEdit({
      ...edit,
      isView: true,
      id: param?.row?.id,
    });
  };


  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getAll("", switchActive, offset)
  };


  const onFeatureGroupSearch = (search = "") => {
    setSearchkey(search)
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAll(e, switchActive, 0);

  };
  React.useEffect(() => {
    getAll(searchkey, switchActive, 0);
    // eslint-disable-next-line
  }, [switchActive, pageSize]);

  const maualResponse = (array) => {
    const details = array?.data?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };

  return (
    <div>
      <TopNavBar
        title={t("feature_groups")}
        isSearch
        buttonLabel="Create Group"
        isFilter
        functions={openDrawer}
        onChangeSearch={onFeatureGroupSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      <div className={classes.root}>
        <TableComponent
          dataList={tData?.rows ?? []}
          Header={FeturetableJson(onEdit)}
          isChecked
          cellClick={(rowData) => handleRowclick(rowData)}
          totalCount={tData?.count}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => handlePagination(newPage)}
        />
      </div>
      {/* drawer */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={open}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.title} noWrap>
                Create new features group
              </Typography>
              <Box>
                {!edit.isView && (
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={SaveNew}
                    className={classes.drawerButtonTittle}
                    disabled={loading}
                  >
                    {loading ? "saving..." : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              <div className={classes.sections}>
                <InputLabel className={classes.label}>
                  features group Name<sup>*</sup>
                </InputLabel>
                <TextField
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  disabled={edit.isView}
                  placeholder="features group Name"
                  size="small"
                  type="text"
                  value={data?.name}
                  onChange={(e) => updateState("name", e.target.value)}
                />

                {data?.error?.name !== "" && (
                  <Typography style={{ fontSize: "10px", color: "red" }}>
                    {data?.error?.name}
                  </Typography>
                )}

                <InputLabel className={classes.label}>
                  Description<sup>*</sup>
                </InputLabel>

                <TextField
                  className={classes.input}
                  placeholder="Description"
                  multiline
                  fullWidth
                  disabled={edit.isView}
                  rows={2}
                  maxRows={4}
                  value={data?.description}
                  onChange={(e) => updateState("description", e.target.value)}
                />
                {data?.error?.description !== "" && (
                  <Typography style={{ fontSize: "10px", color: "red" }}>
                    {data?.error?.description}
                  </Typography>
                )}

                <SelectBox
                  isRequired
                  label="Select Modules"
                  placeholder="Select Modules"
                  options={[]}
                  value={data?.module ?? ""}
                  onChange={(value) => {
                    updateState("module", value);
                  }}
                  isPaginate={true}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      `${config.property_automate_api}/pasm/modules`,
                      { "x-api-key": config.api_key },
                      {},
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      maualResponse
                    )
                  }
                  isError={data?.error?.module?.length > 0}
                  errorMessage={data?.error?.module}
                />

                {/* <Checkbox color="primary" value={data.Checkbox}/> */}
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={data?.is_active}
                      disabled={!edit.isEdit}
                      onChange={(e) =>
                        updateState("is_active", !data.is_active)
                      }
                      color="primary"
                      value="Check to activate this features group"
                    />
                  }
                  label="Check to activate this features group"
                />
              </div>
              <div className={classes.sections}>
                <Box className={classes.addonSectionTittle}>
                  <Typography variant="h6">Features</Typography>
                  <Typography>Check to active</Typography>
                </Box>

                {fData?.length > 0 && fData?.map((v, index) => {
                  return (
                    <Box className={classes.addonSection} key={index}>
                      <Typography>{v.name}</Typography>
                      <Checkbox
                        color="primary"
                        disabled={edit.isView}
                        checked={data?.MData?.some((item) => item === v.id)}
                        onChange={(e) => handleFeatureChange(e, v)}
                      />
                    </Box>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
