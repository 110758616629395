export const IntialState = {
  customer_id: null,
  company_name: "",
  sub_id: [],
  business_phone: "",
  alternative_phone: "",
  company_mail_id: "",
  company_website: "",
  is_active: false,
  address1: "",
  address2: "",
  landmark: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  custom_name: "",
  custom_domain: "",
  admins: [],
  loading: false,
  pageLoading: false,
  logo: {
    src: "",
  },
  error: {
    company_name: "",
    custom_name: "",
    business_phone: "",
    alternative_phone: "",
    company_mail_id: "",
    company_website: "",
    address1: "",
    address2: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    admins: "",
    logo: "",
  },
};

export const returnEditPayload = (data) => {
  const result = {
    customer_id: data?.id,
    company_name: data?.company_name,
    custom_name: data?.client_details?.custom_name,
    custom_domain: data?.client_details?.custom_domain,
    business_phone: data?.business_phone,
    alternative_phone: data?.alternate_phone,
    company_mail_id: data?.company_email_id,
    company_website: data?.company_website,
    is_active: data?.is_active,
    address1: data?.address_line_1,
    address2: data?.address_line_2,
    landmark: data?.landmark,
    city: data?.city,
    state: data?.state,
    country: data?.country,
    pincode: data?.pincode,
    sub_id: data?.subscriptions?.map((i) => i?.id),
    admins: data?.admin_meta ?? [],
    loading: false,
    pageLoading: false,
    logo: {
      src: data?.logo,
    },
    error: {
      company_name: "",
      custom_name: "",
      business_phone: "",
      alternative_phone: "",
      company_mail_id: "",
      company_website: "",
      address1: "",
      address2: "",
      landmark: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      admins: "",
      logo: "",
    },
  };
  return result;
};
