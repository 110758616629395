import ModeEditIcon from "@mui/icons-material/ModeEdit";
import moment from "moment";

const isStstus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? "#3f51b5" : "#efbb5aeb",
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

const isdate = (cell, param) => {
  return <span>{cell ? moment(cell).format("YYYY/MM/DD") : "-"}</span>;
};

export const CunstomertableJson = (onEdit) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      // { field: "id", headerName: "Subscription id", width: 150 },
      { field: "company_name", headerName: "Customer name", width: 150 },
      { field: "mobile_no", headerName: "Bussiness phone", width: 150 },
      { field: "email_id", headerName: "Bussiness email", width: 200 },
      // {
      //   field: "planname",
      //   headerName: "Plan name",
      //   width: 200,
      // },
      {
        field: "is_active",
        headerName: "Plan status",
        width: 200,
        sortable: false,
        renderCell: (params) => {
          return isStstus(params.value, params);
        },
      },
      // {
      //   field: "revenue",
      //   headerName: "Revenue",
      //   width: 200,
      // },
      {
        field: "subs_start_at",
        headerName: "Active form",
        width: 200,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "valid_till",
        headerName: "Next billing on",
        width: 200,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <ModeEditIcon
                style={{ color: "#00000073" }}
                onClick={(e) => onEdit(params.value, params, e)}
              />
              {/* <Link
                to={{
                  pathname: "/subscriptiondetails",
                  state: {
                    isEdit: true,
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ModeEditIcon style={{ color: "#00000073" }} />
              </Link> */}
            </div>
          );
        },
      },
    ],

    //  table dummy datas
    TableData: [],
  };
};

// invoice

export const Invoicedata = {
  //  table columns tittle
  TablecolumnData: [
    { field: "id", headerName: "Invoice number", width: 150 },
    { field: "invoicedate", headerName: "Invoice date", width: 150 },
    { field: "invoiceamount", headerName: "Invoice amount", width: 150 },
    { field: "invoicestatus", headerName: "Invoice status", width: 200 },
    { field: "Action", headerName: "Action", width: 200 },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: () => {
        return (
          <div style={{ textDecoration: "none", cursor: "pointer" }}>
            View Invoice
          </div>
        );
      },
    },
  ],

  //  table dummy datas
  TableData: [],
};

export const Billing = [
  { label: "Monthly", value: "Monthly" },
  { label: "Yearly", value: "Yearly" },
];
