import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Box, Typography } from "@mui/material";

// material UI styles
const useStyles = makeStyles((theme) => ({
  pricingflexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #00000026",
    padding: "10px 0px",
    "&:nth-child(4)": {
      borderBottom: "none",
    },
  },
  pricingDetails: {
    borderRadius: 6,
    border: "1px solid",
    borderColor: theme.palette.gray.dark,
    background: theme.palette.blue.light,

    // backgroundColor: "#3f51b51f",
    color: "black",
    padding: "10px 15px",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "283px",
    },
  },

  pricingDetailsMain: {
    marginTop: "20px",
    paddingTop: "15px",
    borderTop: "1px dashed",
    borderColor: theme.palette.primary.main,
  },
  customerEmail: {
    marginRight: "5px",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      marginRight: "0px",
    },
  },
  onTrailbutton: {
    border: "2px solid #0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#0f2b79",
    fontWeight: 600,
    padding: "4px",
    borderRadius: "6px",
    fontSize: 13,
  },

  pricingDetailsTittle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  pricingflexBoxTittle: {
    fontSize: "13px",
    fontWeight: 600,
  },
  root: {
    margin: "20px 0px",
  },
}));
// end

export const PricingDetails = (props) => {
  // use classes
  const classes = useStyles(props);
  //   end

  return (
    <div className={classes.root}>
      {/* content */}

      {/* Pricing details section */}
      <Box>
        <Typography className={classes.pricingDetailsTittle}>
          {props?.heading}
        </Typography>

        <Box className={classes.pricingDetails}>
          {/* trail section */}
          {props?.cuserDetailshide && (
            <Box className={classes.pricingflexBox}>
              <Box>
                <Typography className={classes.pricingflexBoxTittle}>
                  {props?.customer_name}({props?.customer_id})
                </Typography>
                <Typography className={classes.customerEmail}>
                  {props?.customer_email} | {props?.customer_number}
                </Typography>
              </Box>

              <Box>
                <Box variant="contained" className={classes.onTrailbutton}>
                  On Trail
                </Box>
              </Box>
            </Box>
          )}

          {/* plan section */}
          <Box className={classes.pricingflexBox}>
            <Box>
              <Typography className={classes.pricingflexBoxTittle}>
                {props?.plantittle}
              </Typography>
              <Typography>
                {props?.planename} | {props?.user} | {props?.yearly}{" "}
              </Typography>
            </Box>
            <Box>
              <Typography>{props?.planamount}</Typography>
            </Box>
          </Box>

          {/* Add on section */}
          <Box className={classes.pricingflexBox}>
            <Box>
              <Typography className={classes.pricingflexBoxTittle}>
                {props?.addon} ({props?.addno})
              </Typography>
              <Typography>{props?.Report} </Typography>
            </Box>
            <Box>
              <Typography>{props?.addamount} </Typography>
            </Box>
          </Box>

          {/* Total section */}
          <Box className={classes.pricingflexBox} style={{ border: "none" }}>
            <Box>
              <Typography variant="h6">Total</Typography>
            </Box>
            <Box>
              <Typography variant="h5">{props?.totalamount}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* end */}
    </div>
  );
};
