import makeStyles from "@mui/styles/makeStyles";
export const useFeatureStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
  },
  drawerwidth: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "77px",
    padding: "15px 24px 20px",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
  },
  closeIcondrawermap: {
    right: "520px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "14px",
    },
  },
  title: {
    fontWeight: 600,
  },
  createnewsection: {
    padding: "10px 0px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
  },
  sections: {
    padding: "0px 20px",
  },
  label: {
    marginTop: "15px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  input: {
    marginTop: "5px",
  },
  label: {
    marginTop: "15px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
}));
