import { Box, Typography, Stack, Divider } from "@mui/material";
import { useStyles } from "./styles";

export const CustomerCard = ({ data = {}, onClick = () => false, value }) => {
  const classes = useStyles();

  return (
    <Box
      className={data?.id === value?.id ? classes.selectedRoot : classes.root}
      onClick={onClick}
    >
      <Typography className={classes.title}>{data?.company_name}</Typography>
      <Stack
        direction="row"
        marginTop="4px"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Typography className={classes.data}>
          {data?.company_email_id}
        </Typography>
        <Typography className={classes.data}>
          {data?.admin_meta?.length} Admins
        </Typography>
      </Stack>
    </Box>
  );
};
