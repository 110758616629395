import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};

export const FeatureData = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "id", headerName: "ID", width: 400 },
      {
        field: "name",
        headerName: "Features",
        width: 250,
      },
      {
        field: "updated_at",
        headerName: "Create on",
        width: 250,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <ModeEditIcon
                onClick={(e) => onEdit(params, params, e)}
                style={{ cursor: "pointer", color: "#00000073" }}
              />
            </div>
          );
        },
      },
    ],

    //  table dummy datas
    TableData: [],
  };
};
