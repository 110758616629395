import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const isStstus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? "#3f51b5" : "#efbb5aeb",
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

const isdate = (cell, param) => {
  return <span>{cell && moment(cell).format("MMM Do, YYYY")}</span>;
};

export const AddontableJson = (onEdit, onDelete) => {
  return {
    //  table columns tittle
    TablecolumnData: [
      { field: "id", headerName: "ID", width: 350 },
      { field: "name", headerName: "Addon Sub Category", width: 170 },
      { field: "description", headerName: "Description", width: 170 },
      { field: "name", headerName: "Addon Category", width: 170 },
      {
        sortable: false,
        field: "is_active",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          return isStstus(params.value, params);
        },
      },
      {
        field: "created_at",
        headerName: "Created on",
        width: 150,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <ModeEditIcon
                onClick={(e) => onEdit(params.value, params, e)}
                style={{ cursor: "pointer", color: "#00000073" }}
              />
              {/* <DeleteForeverIcon
                onClick={() => onDelete(params.value, params)}
                style={{ cursor: "pointer", color: "#00000073" }}
              /> */}
            </div>
          );
        },
      },
    ],

    //  table dummy datas
    TableData: [],
  };
};
