import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { TextBox } from "../../../components";
import { customerUseStyles } from "../styles";
import { LogoUpload } from "../../../components/LogoUpload";

export const DetailsForm = ({
  handleValue = () => false,
  values = {},
  state,
}) => {
  const classes = customerUseStyles();

  return (
    <Box>
      <Box p={1} className={classes.box}>
        <Grid container spacing={2} p={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Customer Logo</Typography>
          </Grid>
          <Grid item xs={3}>
            <LogoUpload
              isrequired
              logo_title={""}
              handleChange={(value) => handleValue("logo", value)}
              data={values?.logo}
              isError={values?.logo?.error ?? false}
              errorMessage={values?.logo?.errorMsg ?? false}
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={1} marginTop="12px" className={classes.box}>
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Customer Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextBox
              isReadonly={state?.isView}
              value={values?.company_name}
              onChange={(e) => handleValue("company_name", e.target.value)}
              placeholder="Enter Company Name"
              label="Company Name"
              isrequired
              isError={values?.error?.company_name?.length > 0}
              errorMessage={values?.error?.company_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.business_phone}
              onChange={(e) => handleValue("business_phone", e.target.value)}
              placeholder="Enter Bussiness phone"
              label="Bussiness phone"
              isrequired
              isError={values?.error?.business_phone?.length > 0}
              errorMessage={values?.error?.business_phone}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.alternative_phone}
              onChange={(e) => handleValue("alternative_phone", e.target.value)}
              placeholder="Enter Alternative phone"
              label="Alternative phone"
              isrequired
              isError={values?.error?.alternative_phone?.length > 0}
              errorMessage={values?.error?.alternative_phone}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.company_mail_id}
              onChange={(e) => handleValue("company_mail_id", e.target.value)}
              placeholder="Enter Company's email id"
              label="Company's email id"
              isrequired
              isError={values?.error?.company_mail_id?.length > 0}
              errorMessage={values?.error?.company_mail_id}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.company_website}
              onChange={(e) => handleValue("company_website", e.target.value)}
              placeholder="Enter Company website"
              label="Company website"
              isrequired
              isError={values?.error?.company_website?.length > 0}
              errorMessage={values?.error?.company_website}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={values?.is_active}
                  disabled={state?.isView}
                  onChange={(e) => handleValue("is_active", !values.is_active)}
                  color="primary"
                  value="Check to activate this features group"
                />
              }
              label="Status"
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={1} marginTop="12px" className={classes.box}>
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Customer Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.address1}
              onChange={(e) => handleValue("address1", e.target.value)}
              placeholder="Enter Address line 1"
              label="Address line 1"
              isrequired
              isError={values?.error?.address1?.length > 0}
              errorMessage={values?.error?.address1}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.address2}
              onChange={(e) => handleValue("address2", e.target.value)}
              placeholder="Enter Address line 2"
              label="Address line 2"
              isrequired
              isError={values?.error?.address2?.length > 0}
              errorMessage={values?.error?.address2}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.landmark}
              onChange={(e) => handleValue("landmark", e.target.value)}
              placeholder="Enter Landmark"
              label="Landmark"
              isrequired
              isError={values?.error?.landmark?.length > 0}
              errorMessage={values?.error?.landmark}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.city}
              onChange={(e) => handleValue("city", e.target.value)}
              placeholder="Enter City"
              label="City"
              isrequired
              isError={values?.error?.city?.length > 0}
              errorMessage={values?.error?.city}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.state}
              onChange={(e) => handleValue("state", e.target.value)}
              placeholder="Enter State"
              label="State"
              isrequired
              isError={values?.error?.state?.length > 0}
              errorMessage={values?.error?.state}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.country}
              onChange={(e) => handleValue("country", e.target.value)}
              placeholder="Enter country"
              label="Country"
              isrequired
              isError={values?.error?.country?.length > 0}
              errorMessage={values?.error?.country}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.pincode}
              onChange={(e) => handleValue("pincode", e.target.value)}
              placeholder="Enter Pincode"
              label="Pincode"
              isrequired
              isError={values?.error?.pincode?.length > 0}
              errorMessage={values?.error?.pincode}
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={1} marginTop="12px" className={classes.box}>
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              Listing Configuration
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.custom_name}
              onChange={(e) => handleValue("custom_name", e.target.value)}
              placeholder="Enter Custom Name"
              label="Custom Name"
              isrequired
              isError={values?.error?.custom_name?.length > 0}
              errorMessage={values?.error?.custom_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              isReadonly={state?.isView}
              value={values.custom_domain}
              onChange={(e) => handleValue("custom_domain", e.target.value)}
              placeholder="Enter Custom Domain"
              label="Custom Domain"
              isError={values?.error?.custom_domain?.length > 0}
              errorMessage={values?.error?.custom_domain}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
