import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  IconButton,
  Checkbox,
  Stack,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { PricingInput } from "../pricinginput";

const useStyles = makeStyles((theme) => ({
  labelTittle: {
    fontSize: "18px",
    fontWeight: 600,
    padding: "10px 15px",
  },
  subTittle: {
    fontSize: "16px",
    fontWeight: 600,
    paddingLeft: "10px",
  },
  subTittlePlan: {
    fontSize: "15px",
    fontWeight: 500,
  },
  inputText: {
    fontSize: "16px",
    marginLeft: "10px",
    minWidth: "83px",
  },
  limitedRadio: {
    marginLeft: "15px",
    marginRight: "0",
  },
  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: theme.palette.blue.main,
    },
  },
  setPricingMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  setPricing: {
    marginTop: "10px",
    "& sup": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root": {
      width: "200px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  pricing: {
    display: "flex",
    marginTop: "10px",
  },
  move: {
    fontSize: 18,
  },
  mobileBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      // justifyContent: "space-between",
    },
  },
  accordiondiv: {
    "& div": {
      alignItems: "center",
    },
  },
  accordionsummary: {
    marginBottom: "0px !important",
  },
  accContent: {
    marginTop: "10px",
    height: "auto",
    padding: "16px  0",
    background: "white",
    borderRadius: "6px",
    transition: "all 0.7s ease",
  },
  shrinkAccContent: {
    height: "0px !important",
    overflow: "hidden",
    transition: "all 0.7s ease",
    background: "white",
    borderRadius: "6px",
  },
  ruleText: {
    display: "flex",
    justifyContent: "center",
  },
  limitGroup: {
    marginLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
  ruleFirstText: {
    marginLeft: "12px",
  },
  featureName: {
    display: "flex",
    alignItems: "center",
    background: "white",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  planChildDiv: {
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      margin: "3px",
      textAlign: "center",
    },
  },
  planGrid: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
}));

export const FeaturesCard = (props) => {
  // use classes
  const classes = useStyles(props);
  //   end
  const [expanded, setExpanded] = React.useState(false);

  const featureChange = (key, value, val, checkLimit, id) => {
    val[key] = value;
    checkLimit = "limited" ? setExpanded(!expanded) : null;
  };

  const {
    setState,
    state,
    Editstate,
    // , sample,type
  } = props;

  // console.clear();

  return (
    <div className={props?.ActivityCard}>
      {state?.map((val, index) => {
        //
        return (
          <Grid container className={props?.contantcard}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              display="flex"
              alignItems="center"
              className={classes.accordiondiv}
            >
              {Editstate ? (
                <Box style={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    className={classes.featureName}
                  >
                    <Typography
                      variant="h6"
                      style={{ padding: "12px" }}
                      className={classes.subTittle}
                    >
                      {val?.feature_name ?? val?.feature?.name}
                    </Typography>
                  </Stack>
                  {val?.plan_feature_rules?.[0] ? (
                    <Grid container className={classes.accContent}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center">
                          <Checkbox
                            color="primary"
                            value={val?.feature_id}
                            onChange={(e) =>
                              props?.setChecked(
                                e.target.checked,
                                val,
                                val?.feature_id,
                                index,
                                props?.groupName,
                                props?.index
                              )
                            }
                            checked={val?.is_active}
                            disabled={props?.disabled}
                          />
                          <Typography className={classes.subTittlePlan}>
                            {val?.plan_feature_rules?.[0]?.name}
                          </Typography>
                        </Stack>
                      </Grid>
                      {val?.is_active && (
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <FormControl>
                            <RadioGroup row name="row-radio-buttons-group">
                              <Stack direction="row" alignItems="center">
                                <FormControlLabel
                                  checked={
                                    val?.plan_feature_rules?.[0]
                                      ?.rule_master_type?.check_type ===
                                    "Unlimited"
                                  }
                                  value={"Unlimited"}
                                  control={<Radio />}
                                  label="Unlimited"
                                  disabled={props?.disabled}
                                  onClick={(e) =>
                                    props.radioChange(
                                      setState,
                                      state,
                                      "control_type",
                                      e.target.value,
                                      index,
                                      e,
                                      props?.groupName,
                                      props?.index
                                    )
                                  }
                                />
                                <FormControlLabel
                                  checked={
                                    val?.plan_feature_rules?.[0]
                                      ?.rule_master_type?.check_type === "limit"
                                  }
                                  value={"limit"}
                                  control={<Radio />}
                                  label="Limited"
                                  className={classes.limitedRadio}
                                  disabled={props?.disabled}
                                  onClick={(e) =>
                                    props?.radioChange(
                                      setState,
                                      state,
                                      "control_type",
                                      e.target.value,
                                      index,
                                      val,
                                      props?.groupName,
                                      props?.index
                                    )
                                  }
                                />
                              </Stack>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                      {val?.is_active && (
                        <Grid
                          item
                          lg={4}
                          md={4}
                          sm={6}
                          xs={12}
                          className={classes.mobileBottom}
                        >
                          {val?.plan_feature_rules?.[0]?.rule_master_type
                            ?.check_type === "limit" && (
                            <div>
                              <TextField
                                label="threshold"
                                type="number"
                                style={{ width: "150px" }}
                                size="small"
                                className={classes.move}
                                onChange={(e) =>
                                  props?.inputChange(
                                    setState,
                                    state,
                                    "threshold",
                                    e.target.value,
                                    index,
                                    val,
                                    props?.groupName,
                                    props?.index
                                  )
                                }
                                value={
                                  val?.plan_feature_rules?.[0]?.check_json
                                    ?.limit
                                }
                                disabled={props?.disabled}
                              />
                              <Typography
                                variant="h6"
                                className={classes.inputText}
                              >
                                {val?.inputText}
                              </Typography>
                            </div>
                          )}

                          {props?.isCancel && (
                            <IconButton
                              disabled={props?.disabled}
                              onClick={() =>
                                props.DeleteCard(setState, val, index)
                              }
                            >
                              <CancelOutlinedIcon />
                            </IconButton>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    []
                  )}
                </Box>
              ) : (
                <Box style={{ width: "100%" }}>
                  <Box className={classes.featureName}>
                    <Typography variant="h6" className={classes.subTittle}>
                      {val?.feature_name ?? val?.feature?.name}
                    </Typography>
                    <FormControl className={classes.limitGroup}>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={val?.check_limit}
                        onChange={(e) => {
                          featureChange(
                            "check_limit",
                            e?.target?.value,
                            val,
                            val?.check_limit,
                            index
                          );
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <FormControlLabel
                            value={"unlimited"}
                            control={<Radio />}
                            label="Unlimited"
                          />
                          <FormControlLabel
                            value={"limited"}
                            control={<Radio />}
                            label="Limited"
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    className={
                      val?.check_limit === "limited"
                        ? classes.accContent
                        : classes.shrinkAccContent
                    }
                  >
                    <Grid
                      container
                      className={classes.planChildDiv}
                      key={val?.rules?.[0]?.ruleId}
                    >
                      <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                        className={classes.planGrid}
                      >
                        <Typography className={classes.ruleFirstText}>
                          {val?.rules?.[0]?.rule_master_name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                        className={classes.planGrid}
                      >
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          placeholder="Rule Name"
                          size="small"
                          type="text"
                          value={val?.rules?.[0]?.name}
                          onChange={(e) =>
                            props?.inputthresoldChange(
                              "name",
                              e.target.value,
                              index,
                              val,
                              props?.groupName,
                              props?.index
                            )
                          }
                          disabled={state?.isView}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                        className={classes.planGrid}
                      >
                        <Typography className={classes.ruleText}>
                          {val?.rules?.[0]?.check_type}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                        className={classes.planGrid}
                      >
                        <TextField
                          label="threshold"
                          type="number"
                          style={{ width: "150px" }}
                          size="small"
                          className={classes.move}
                          onChange={(e) =>
                            props?.inputthresoldChange(
                              "threshold_value",
                              e.target.value,
                              index,
                              val,
                              props?.groupName,
                              props?.index
                            )
                          }
                          value={val?.rules?.[0]?.threshold}
                          disabled={props?.disabled}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Grid>

            {props?.isPrice && (
              <div className={classes.setPricingMain}>
                <div className={classes.setPricing}>
                  <Typography>
                    Set Pricing <sup>*</sup>
                  </Typography>
                  <Box className={classes.pricing}>
                    <Box>
                      <PricingInput
                        tittle={" Yearly"}
                        type={"number"}
                        placeholder={"Amount..."}
                        onChange={(e) =>
                          props?.inputChange(
                            setState,
                            state,
                            "yearly_price",
                            e.target.value,
                            index,
                            val
                          )
                        }
                        amount={val?.yearly_price ? val?.yearly_price : null}
                        disabled={props?.disabled}
                      />
                    </Box>
                    <Box style={{ marginLeft: "10px" }}>
                      <PricingInput
                        tittle={" Monthly"}
                        type={"number"}
                        placeholder={"Amount..."}
                        onChange={(e) =>
                          props?.inputChange(
                            setState,
                            state,
                            "monthly_price",
                            e.target.value,
                            index,
                            val
                          )
                        }
                        amount={val?.monthly_price ? val?.monthly_price : null}
                        disabled={props?.disabled}
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            )}
          </Grid>
        );
      })}

      {/* add button section */}
      {props?.isButtonNot && (
        <div>
          <Button
            className={classes.createButton}
            onClick={() => props?.addFeatures(props?.type)}
            disabled={props?.disabled}
          >
            {props?.buttontext}
          </Button>
        </div>
      )}
    </div>
  );
};
