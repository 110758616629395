import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px",
    backgroundColor: "#E9EBEE",
    borderRadius: "4px",
    border: "1px solid #80808040",
    marginTop: "12px",
    cursor: "pointer",
  },
  title: {
    fontSize: "14px",
    fontFamily: "crayond_bold",
  },
  data: {
    fontSize: "13px",
    fontFamily: "crayond_regular",
  },
  selectedRoot: {
    backgroundColor: "#d7e2ff87",
    padding: "12px",
    borderRadius: "4px",
    border: "1px solid #3f51b5",
    marginTop: "12px",
    cursor: "pointer",
  },
}));
