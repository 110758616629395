import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  selected_menu: "selected_menu",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const allowed_file_size = 5;

/**
 * side navbar menu listing
 */
export let SideNavbarMenu = [
  {
    id: "ff4a9dd6-132a-43ee-b454-16a79d731980",
    link: "/",
    tittle: "Dashboard",
    sub_link: "/dashboard",
    icon: <DashboardIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "71b69c6e-d99a-4032-b72d-9d7382ae6b59",
    link: "/customers",
    tittle: "customers",
    sub_link: "/customersdetails",
    icon: <GroupAddIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "5de4b98a-03f5-4882-a937-cf26372fe26d",
    link: "/subscription",
    tittle: "subscription",
    sub_link: "/subscriptiondetails",
    icon: <NotificationsActiveIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "1dc850e8-fbe8-45b5-96af-c29ed703723b",
    link: "/features",
    tittle: "Features",
    icon: <GroupsIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "d7459797-a5cd-47bc-bf70-df1a45a1c58a",
    link: "/featuresGroup",
    tittle: "feature_groups",
    icon: <GroupsIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "19d3da81-9b23-45cf-8a40-8ce8aa8a5f7c",
    link: "/plan",
    tittle: "plans",
    sub_link: "/plandetails",
    icon: <AssessmentIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "cfbb9a8d-f0bd-4052-be79-96baaabdc2a1",
    link: "/addons",
    tittle: "add_ons",
    icon: <AddBoxIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "cfbb9a8d-f0bd-4052-be79-96baaabdc2b1",
    link: "/addonCategory",
    tittle: "Addon Category",
    icon: <AddBoxIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "cfbb9a8d-f0bd-4052-be79-96baaabdc2c1",
    link: "/addonsubCategory",
    tittle: "Addon Sub Category",
    icon: <AddBoxIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "e9b65bbf-ac2c-4a89-bc80-1d11c61ef6f6",
    link: "/charges",
    tittle: "charges",
    icon: <MonetizationOnIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "e9b65bbf-ac2c-4a89-bc80-1d11c61ef6f7",
    link: "/logoConfiguration",
    tittle: "Logo Configuration",
    icon: <MonetizationOnIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
  {
    id: "a93c34f4-df4a-42d4-91b5-3eef67b11cc3",
    link: "/coupons",
    tittle: "Coupons",
    icon: <AccountBalanceWalletIcon style={{ opacity: 0.7 }} />,
    children: [],
  },

  {
    id: "6c278412-7c91-4bed-b547-374c7838f001",
    link: "/configuration",
    tittle: "configuration",
    icon: <SettingsApplicationsIcon style={{ opacity: 0.7 }} />,
    children: [],
  },

  {
    id: "218dea51-11bb-417a-90d4-65aaa9e9f291",
    link: "/settings",
    tittle: "settings",
    icon: <SettingsApplicationsIcon style={{ opacity: 0.7 }} />,
    children: [],
  },
];

/**
 * cusotomer input menu
 */
// start
export let planselectinput = [
  {
    id: 0,
    value: "Gold",
  },
  {
    id: 1,
    value: "silver",
  },

  {
    id: 2,
    value: "Diamond",
  },
];
export let billingselectinput = [
  {
    id: 0,
    value: "Montly",
  },
  {
    id: 1,
    value: "Yearly",
  },
];

export let Genral = [
  {
    id: 1,
    subTittle: "User",
    isUserInput: true,
    inputText: "User",
    type: "",
    userInput: "",
  },
  {
    id: 2,
    subTittle: "Action",
    isUserInput: true,
    inputText: "Action",
    type: "",
    userInput: "",
  },
  {
    id: 3,
    subTittle: "Goals",
    isUserInput: true,
    inputText: "Goals",
    type: "",
    userInput: "",
  },
];

export let Actions = [
  {
    id: 1,
    subTittle: "Reports",
    isUserInput: false,
    inputText: "Reports",
  },
  {
    id: 2,
    subTittle: "Customized metrics",
    isUserInput: false,
    inputText: "Customized metrics",
  },
  {
    id: 3,
    subTittle: "Productivity framework",
    isUserInput: false,
    inputText: "Productivity framework ",
  },
];

// let manoNaJsonModel = {
//   featureParentData: [
//       {
//           feature_group_name: "user_management",
//           id: "1234",
//           features: [
//               {
//                   feature_name: "feature_one",
//                   feature_id: "2345"
//               },
//               {
//                   feature_name: "feature_two",
//                   feature_id: "3456"
//               }
//           ]
//       },
//       {
//           feature_group_name: "property_management",
//           id: "4321",
//           features: [
//               {
//                   feature_name: "feature_a",
//                   feature_id: "23456"
//               },
//               {
//                   feature_name: "feature_b",
//                   feature_id: "34567"
//               }
//           ]
//       },
//       {
//           feature_group_name: "Invoice_management",
//           id: "3214",
//           features: [
//               {
//                   feature_name: "feature_3",
//                   feature_id: "234567"
//               },
//               {
//                   feature_name: "feature_4",
//                   feature_id: "345678"
//               }
//           ]
//       },
//       {
//           feature_group_name: "Payment_management",
//           id: "32145",
//           features: [
//               {
//                   feature_name: "feature_get",
//                   feature_id: "2345678"
//               },
//               {
//                   feature_name: "feature_post",
//                   feature_id: "3456789"
//               }
//           ]
//       },
//       {
//           feature_group_name: "Host_management",
//           id: "321456",
//           features: [
//               {
//                   feature_name: "host_up",
//                   feature_id: "23456789"
//               },
//               {
//                   feature_name: "host_down",
//                   feature_id: "34567890"
//               }
//           ]
//       }
//   ],
//   plans: [
//       {
//           plan_name: "Basic",
//           color: "#78B1FE",
//           amount: "1",
//           allowed_features: [
//               {
//                   feature_group_name: "user_management",
//                   id: "1234",
//                   features: [
//                       {
//                           feature_name: "feature_one",
//                           feature_id: "2345"
//                       },
//                       {
//                           feature_name: "feature_two",
//                           feature_id: "3456"
//                       }
//                   ]
//               }
//           ]
//       },
//       {
//           plan_name: "professional",
//           color: "#5AC782",
//           amount: "2",
//           allowed_features: [
//               {
//                   feature_group_name: "user_management",
//                   id: "1234",
//                   features: [
//                       {
//                           feature_name: "feature_one",
//                           feature_id: "2345"
//                       },
//                       {
//                           feature_name: "feature_two",
//                           feature_id: "3456"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Invoice_management",
//                   id: "3214",
//                   features: [
//                       {
//                           feature_name: "feature_3",
//                           feature_id: "234567"
//                       },
//                       {
//                           feature_name: "feature_4",
//                           feature_id: "345678"
//                       }
//                   ]
//               }
//           ]
//       },
//       {
//           plan_name: "Expert",
//           color: "#F15A29",
//           amount: "3",
//           allowed_features: [
//               {
//                   feature_group_name: "user_management",
//                   id: "1234",
//                   features: [
//                       {
//                           feature_name: "feature_one",
//                           feature_id: "2345"
//                       },
//                       {
//                           feature_name: "feature_two",
//                           feature_id: "3456"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "property_management",
//                   id: "4321",
//                   features: [
//                       {
//                           feature_name: "feature_a",
//                           feature_id: "23456"
//                       },
//                       {
//                           feature_name: "feature_b",
//                           feature_id: "34567"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Invoice_management",
//                   id: "3214",
//                   features: [
//                       {
//                           feature_name: "feature_3",
//                           feature_id: "234567"
//                       },
//                       {
//                           feature_name: "feature_4",
//                           feature_id: "345678"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Payment_management",
//                   id: "32145",
//                   features: [
//                       {
//                           feature_name: "feature_get",
//                           feature_id: "2345678"
//                       },
//                       {
//                           feature_name: "feature_post",
//                           feature_id: "3456789"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Host_management",
//                   id: "321456",
//                   features: [
//                       {
//                           feature_name: "host_up",
//                           feature_id: "23456789"
//                       }
//                   ]
//               }
//           ]
//       },
//       {
//           plan_name: "Prime",
//           color: "#F1A129",
//           amount: "4",
//           allowed_features: [
//               {
//                   feature_group_name: "user_management",
//                   id: "1234",
//                   features: [
//                       {
//                           feature_name: "feature_one",
//                           feature_id: "2345"
//                       },
//                       {
//                           feature_name: "feature_two",
//                           feature_id: "3456"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "property_management",
//                   id: "4321",
//                   features: [
//                       {
//                           feature_name: "feature_a",
//                           feature_id: "23456"
//                       },
//                       {
//                           feature_name: "feature_b",
//                           feature_id: "34567"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Invoice_management",
//                   id: "3214",
//                   features: [
//                       {
//                           feature_name: "feature_3",
//                           feature_id: "234567"
//                       },
//                       {
//                           feature_name: "feature_4",
//                           feature_id: "345678"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Payment_management",
//                   id: "32145",
//                   features: [
//                       {
//                           feature_name: "feature_get",
//                           feature_id: "2345678"
//                       },
//                       {
//                           feature_name: "feature_post",
//                           feature_id: "3456789"
//                       }
//                   ]
//               },
//               {
//                   feature_group_name: "Host_management",
//                   id: "321456",
//                   features: [
//                       {
//                           feature_name: "host_up",
//                           feature_id: "23456789"
//                       },
//                       {
//                           feature_name: "host_down",
//                           feature_id: "34567890"
//                       }
//                   ]
//               }
//           ]
//       }
//   ]
// }
