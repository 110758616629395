import React from "react";
import { CreateSubscription } from "./createSubscription";
import { withNavBars } from "../../HOCs";

class CreateSubscriptionParent extends React.Component {
  render() {
    return <CreateSubscription />;
  }
}

export default withNavBars(CreateSubscriptionParent);
