import React from "react";
import { withNavBars } from "../../HOCs";
import { AddonsCategory } from "./addonCategory";

class AddonCategoryParent extends React.Component {
  render() {
    return <AddonsCategory />;
  }
}

export default withNavBars(AddonCategoryParent);
