import React from "react";
import { FeatureGroup } from "./featuregroup";
import { withNavBars } from "../../HOCs";

class FeatureGroupParent extends React.Component {
  render() {
    return <FeatureGroup />;
  }
}

export default withNavBars(FeatureGroupParent);
