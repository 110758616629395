import makeStyles from "@mui/styles/makeStyles";

export const useStylesDetails = makeStyles((theme) => ({
  root: {
    margin: 20,
    textAlign: "left",
  },
}));

export const useStylesplanDetails = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    height: "95vh",
    overflow: "auto",
  },
  input: {
    marginTop: "5px",
  },
  label: {
    marginTop: "15px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  basicTittle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  customerForm: {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "0px 12px 53px #1d1f7212",
    borderRadius: 6,
    margin: "19px 12px 30px 19px",
    [theme.breakpoints.down("sm")]: {
      margin: "19px 4px 30px 9px",
    },
  },
  sections: {
    padding: "16px",
    borderBottom: "1px dashed",
    borderBottomColor: "#00000026",
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  AccordionParentDiv: {
    marginTop: "10px",
  },
  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: theme.palette.blue.main,
    },
  },
  title: {
    fontWeight: 600,
  },
  sectionscharges: {
    padding: "16px",
  },
  checkSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0px",
    },
  },
  checkBoxes: {
    marginTop: "15px",
  },
  togglebox: {
    marginTop: "10px",
    display: "flex",
  },
  yearlyMonthactive: {
    width: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "4px",
    color: theme.palette.primary.contrastText,
    borderRadius: "6px",
    cursor: "pointer",
  },
  yearlyMonth: {
    width: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "6px",
    cursor: "pointer",
  },

  pointerEventNone: {
    PointerEvents: "none",
  },

  pricing: {
    display: "flex",
    marginTop: "10px",
  },
  setPricing: {
    marginTop: "10px",
    "& sup": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root": {
      width: "200px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  setPricingMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  addCards: {
    margin: "15px 0px",
  },
  ActivityCard: {
    border: "1px solid",
    borderColor: theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
    borderRadius: "6px",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  ActivityCardwithout: {
    borderRadius: "6px",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  contantcardwithout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px",
    borderBottom: "1px solid",
    borderBottomColor: "#00000026",
  },
  contantcard: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: "1px solid",
    borderBottomColor: "#00000026",
  },
  sectionsaddon: {
    padding: "16px 0px",
    borderBottom: "1px dashed",
    borderBottomColor: "#00000026",
  },
  addonTittle: {
    padding: "0px 16px",
    fontSize: "16px",
    fontWeight: 600,
  },
  inputPrams: {
    "& .MuiTypography-root": {
      fontSize: "16px",
      color: theme.palette.common.black,
    },
  },
  ghargeTittle: {
    fontSize: "14px",
  },
  chargesTittlemain: {
    display: "flex",
    justifyContent: "space-between",
  },
  chargesTittlemain1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px 0px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: theme.palette.blue.main,
    },
  },
  closeIcon: {
    cursor: "pointer",
  },
  drawerwidth: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "77px",
    padding: "15px 24px 20px",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
  },
  closeIcondrawermap: {
    right: "520px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "14px",
    },
  },
  drawerButtonTittle: {
    backgroundColor: "#0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },
  addonSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      padding: "5px",
      marginRight: "42px",
    },
  },
  createnewsection: {
    padding: "10px 20px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  module_name: {
    fontSize: "14px",
    margin: "12px 0px",
    fontWeight: 600,
  },
}));

export const FeaturesStyle = makeStyles((theme) => ({
  labelTittle: {
    fontSize: "18px",
    fontWeight: 600,
    padding: "10px 15px",
  },
  subTittle: {
    fontSize: "16px",
    fontWeight: 600,
  },

  inputText: {
    fontSize: "16px",
    marginLeft: "10px",
    minWidth: "83px",
  },

  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: theme.palette.blue.main,
    },
  },
  setPricingMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  setPricing: {
    marginTop: "10px",
    "& sup": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root": {
      width: "200px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  pricing: {
    display: "flex",
    marginTop: "10px",
  },
}));
