import React from "react";
import { ChangePassword } from "./changePassword";

class ChangePasswordParent extends React.Component {
  render() {
    return <ChangePassword />;
  }
}

export default ChangePasswordParent;
