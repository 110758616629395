import React from "react";
import { LinearProgress } from "@mui/material";
import { NetworkCall } from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { config } from "./config";
import { Routes } from "./router/routes";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = () => {
    // console.log('jkjkjkjk')
    // let params = {
    //   search:  "",
    // };
    // NetworkCall(
    //   `${config.api}api/v1/pasm/refresh`,
    //   NetWorkCallMethods.get,
    //   params,
    //   null,
    //   true,
    //   false
    // ).then((res)=>{
    //   console.log(res)
    //      return true
    // }).catch((err)=>{
    //   console.log(err)
    //   localStorage.clear()
    //   return false;
    // })
  };

  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  render() {
    let { loading } = this.state;

    return <>{loading ? <LinearProgress /> : this.props.children}</>;
  }
}

export default AppAuth;
