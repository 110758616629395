import moment from "moment";

const isStstus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? "#3f51b5" : "#efbb5aeb",
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};

export const DemoReqJson = (onEdit, onDelete) => {
  //  table columns tittle
  return {
    TablecolumnData: [
      { field: "sl", headerName: "S.No", width: 80 },
      {
        field: "created_at",
        headerName: "Requested on",
        width: 150,
        renderCell: (params) => {
          return isdate(params.value, params);
        },
      },
      { field: "first_name", headerName: "First Name", width: 150 },
      { field: "last_name", headerName: "Last Name", width: 130 },
      { field: "company_name", headerName: "Company Name", width: 380 },
      { field: "country", headerName: "Country", width: 150 },
      { field: "phone_no", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 250 },

      // { field: "id", headerName: "ID", width: 220 },

      // {
      //   sortable: false,
      //   field: "is_active",
      //   headerName: "Status",
      //   width: 120,
      //   renderCell: (params) => {
      //     return isStstus(params.value, params);
      //   },
      // },

      // { field: "message", headerName: "ID", width: 100 },

      { field: "message", headerName: "Message", width: 130 },
      // {
      //   field: "updated_at",
      //   headerName: "Modified on",
      //   width: 150,
      //   renderCell: (params) => {
      //     return isdate(params.value, params);
      //   },
      // },
    ],

    //  table dummy datas
    TableData: [],
  };
};
