import React from "react";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 90px)",
    width: "100%",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&.MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "0px",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },

  DataGrid: {
    background: "#fff",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 9%)",
    textAlign: "center",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  },
}));

export function TableComponent(props) {
  // use classes
  const classes = useStyles(props);

  const {
    dataList,
    Header,
    isrowCount,
    showAction,
    cellClick,
    pageSize,
    // setPageSize,
    setPageSize = () => false,
    onPageChange,
    totalCount,
    page
  } = props;
  return (
    <div className={`${classes.DataGrid} ${classes.root}`}>
      <DataGrid
        className={classes.root}

        rows={dataList}
        columns={Header?.TablecolumnData}
        disableColumnMenu
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        sortingMode={"client"}
        hideFooterSelectedRowCount={isrowCount}
        onSelectionModelChange={showAction}
        onRowClick={cellClick}
        ExtendRowFullWidth={true}
        onPageChange={onPageChange}
        paginationMode="server"
        rowCount={totalCount}
        page={page}

      // components={{
      //   // Replace the default renderPagination with your custom version
      //   Pagination: (props) => (
      //     <Pagination
      //       {...props}
      //       labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
      //     />
      //   ),
      // }}
      />
    </div>
  );
}
