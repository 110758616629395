import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {
    "& .css-1rhbuit-multiValue": {
      backgroundColor:
        window.location.pathname === "/properties" ? "#E4E8EE" : "#DBEDFE",
    },
  },
  label: {
    color: "red",
    fontSize: "12px",
  },
  labelb: {
    color: "red",
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
}));

export const SelectField = ({
  label = "",
  isRequired = false,
  isPaginate = false,
  isReadOnly = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  reduceOptions = undefined,
  debounceTimeout = 0,
  key,
  bold = false,
  isSelectAll,
  color,
  padding,
  isSearchable = true,
}) => {
  const classes = useStyles();
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: 8,
      backgroundColor: color ?? "none",
      zIndex: 1,
      height: 45,
      fontSize: "16px",
      padding: padding ? padding : "auto",
      minHeight: 45,
      border: "1.5px solid #E2E2E2",
      boxShadow: "none",
      "&:hover": {
        border: "1.5px solid #E2E2E2",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "tenant_regular",
        fontSize: 14,
      };
    },
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        zIndex: 3,
      };
    },
  };

  return (
    <div className={classes.root}>
      {label && (
        <div style={{ display: "flex" }}>
          {
            <Typography
              variant="body1"
              color="textsecondary"
              className={bold ? classes.labelb : classes.label}
              align="left"
              gutterBottom
              noWrap
            >
              {label}
            </Typography>
          }
          {isRequired && (
            <Typography color="error" variant="caption">
              &nbsp;*
            </Typography>
          )}
        </div>
      )}

      {isPaginate ? (
        <AsyncPaginate
          key={key}
          isClearable
          isSearchable={isSearchable}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
          isDisabled={isReadOnly}
          isMulti={isMulti}
          debounceTimeout={debounceTimeout}
          reduceOptions={reduceOptions}
        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          onChange={onChange}
          styles={customStyles}
          isDisabled={isReadOnly}
        />
      ) : (
        <Select
          isClearable={false}
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          label={label}
          autoComplete="off"
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
          className={classes.selects}
          isMulti={isMulti}
          isDisabled={isReadOnly}
          isSelectAll
        />
      )}
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
