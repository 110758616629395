import React from "react";
import { withNavBars } from "../../HOCs";
import { AddonSubCategory } from "./addonSubCategory";

class AddonSubCategoryParent extends React.Component {
  render() {
    return <AddonSubCategory />;
  }
}

export default withNavBars(AddonSubCategoryParent);
