import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import CreateCustomers from "../screens/createCusomers";
import SubscriptionDetails from "../screens/subscription/subscriptiondetails";
import CreateSubscription from "../screens/createSubscription";
import PlanDetails from "../screens/plan/plandetails";

import {
  NotFound,
  CustomersParent,
  SubscriptionParent,
  DashboardParent,
  PlanParent,
  AddonsParent,
  ChargesParent,
  FeatureGroupParent,
  Login,
  ChangePassword,
  Settings,
  Coupons,
  Configuration,
  Features,
  Request,
  Demo,
  PartnerShip,
  AddonCategoryParent,
  AddonSubCategoryParent,
} from "./../screens";
import PrivateRoute from "./privateRouter";
import LogoConfiguration from "../screens/logoConfiguration";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        {/* <Route path={Routes.login}>
          <Login />
        </Route> */}

        <PrivateRoute exact path={Routes.createSubscription}>
          <CreateSubscription />
        </PrivateRoute>

        {/* DashboardParent Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>

        {/* Change Password */}
        <PrivateRoute exact path={Routes.changePassword}>
          <ChangePassword />
        </PrivateRoute>

        {/* DashboardParent Route */}
        <PrivateRoute exact path={Routes.dashboard}>
          <DashboardParent />
        </PrivateRoute>

        {/* CustomersParent Route */}
        <PrivateRoute exact path={Routes.customers}>
          <CustomersParent />
        </PrivateRoute>

        {/* CustomersDetails Route */}
        <PrivateRoute exact path={Routes.customersdetails}>
          <CreateCustomers />
        </PrivateRoute>

        {/* Subscription Route */}
        <PrivateRoute exact path={Routes.subscription}>
          <SubscriptionParent />
        </PrivateRoute>

        {/* Subscription Details */}
        <PrivateRoute exact path={Routes.subscriptiondetails}>
          <SubscriptionDetails />
        </PrivateRoute>

        {/* Plan Route */}
        <PrivateRoute exact path={Routes.plan}>
          <PlanParent />
        </PrivateRoute>

        {/* Plan Details Routes */}
        <PrivateRoute exact path={Routes.plandetails}>
          <PlanDetails />
        </PrivateRoute>

        {/* Add-on Routes */}
        <PrivateRoute exact path={Routes.addons}>
          <AddonsParent />
        </PrivateRoute>
        {/* Add-on Routes */}
        <PrivateRoute exact path={Routes.addonCategory}>
          <AddonCategoryParent />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.addonSubCategory}>
          <AddonSubCategoryParent />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.logoConfiguration}>
          <LogoConfiguration />
        </PrivateRoute>

        {/* Charges Routes */}
        <PrivateRoute exact path={Routes.charges}>
          <ChargesParent />
        </PrivateRoute>

        {/*featuresGroup Routes */}
        <PrivateRoute exact path={Routes.featuresGroup}>
          <FeatureGroupParent />
        </PrivateRoute>

        {/*Coupon Routes */}
        <PrivateRoute exact path={Routes.coupons}>
          <Coupons />
        </PrivateRoute>

        {/*Configuration Routes */}
        <PrivateRoute exact path={Routes.configuration}>
          <Configuration />
        </PrivateRoute>

        {/*settings Routes */}
        <PrivateRoute exact path={Routes.settings}>
          <Settings />
        </PrivateRoute>

        {/* features Routes*/}
        <PrivateRoute exact path={Routes.features}>
          <Features />
        </PrivateRoute>

        {/* Webinar Routes*/}
        {/* <Route exact path={Routes.webinarConfig}>
          <WebinarConfig />
        </Route> */}

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
