import React from "react";
import {
  TableComponent,
  TopNavBar,
  DrawerComponent,
  TextBox,
} from "../../components";
import { AddontableJson } from "./common";
import { useStylesAddon } from "./style";
import {
  Typography,
  Box,
  Button,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Grid,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, Message } from "../../utils";
import { config } from "../../config";
import { BackdropContext, AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { useTranslation } from "react-i18next";
import { UseDebounce } from "../../components/customHooks/useDebounce";
import { LogoUpload } from "../../components/LogoUpload";

let IntialState = {
  name: "",
  description: "",

  is_active: true,
  error: {
    name: "",
    description: "",

  },
};

export const AddonsCategory = (props) => {
  // use classes
  const classes = useStylesAddon(props);

  const { t } = useTranslation(["topBar"]);

  // end
  const [data, setData] = React.useState({ ...IntialState });

  const [tData, setTdata] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [tempData, setTempData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [dropData, setDropData] = React.useState({
    group: [],
    single: [],
  });

  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });
  //   drawer
  const [open, setOpen] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const updateState = async (key, value, label) => {
    setData({ ...data, [key]: value });
  };

  const isIamValid = () => {
    let isValid = true;
    let error = data.error;

    if (data.name === "") {
      isValid = false;
      error.name = "Enter Name";
    }

    if (data.description === "") {
      isValid = false;
      error.description = "Enter Description";
    }
    setData({ ...data, error });
    return isValid;
  };

  // on Edit
  const onEdit = async (cell, param, e) => {
    if (e) {
      e.stopPropagation();
    }
    setData({
      name: param?.row?.name,
      description: param?.row?.description,
      is_active: param?.row?.is_active,
      logo: { src: param?.row?.logo },


    });

    setOpen(true);
    setEdit({
      ...edit,
      isEdit: true,
      isView: e ? false : true,
      id: param?.row?.id,
    });
  };

  // Save
  const SaveNew = () => {
    if (isIamValid()) {
      let param;
      {
        edit.isEdit
          ? (param = {
            addon_category_id: edit?.id,
            name: data.name,
            description: data.description,
            logo: data?.logo?.src ?? undefined

          })
          : (param = {
            name: data.name,
            description: data.description,
            logo: data?.logo?.src ?? undefined



          });
      }
      setLoading(true);
      NetworkCall(
        `${config.api_rest}${edit.isEdit ? "api/v1/addon_category/update" : "api/v1/addon_category/new"
        }`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          setLoading(false);
          let main = response.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });

          setOpen(false);
          getAll("", switchActive, 0);
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          setLoading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.addOnErr,
          });
        });
    } else {
      return false;
    }
  };

  const closeDrawer = () => {
    setData({ ...IntialState });
    setOpen(false);
  };

  const openDrawer = () => {
    setData({ ...IntialState });
    setEdit({ ...edit, isEdit: false, isView: false });
    setOpen(true);
  };



  // Get All
  const getAll = (search, filter, offset) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter,
      limit: pageSize,
      offset: offset,
    };

    NetworkCall(
      `${config.api_rest}api/v1/addon_category/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setTdata(main);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };



  const handleRowclick = (param) => {
    onEdit("", param);
  };


  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getAll("", switchActive, offset)
  };


  const onAddOnSearch = (search = "") => {
    setSearchkey(search)
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAll(e, switchActive, 0);

  };
  React.useEffect(() => {
    getAll(searchkey, switchActive, 0);
    // eslint-disable-next-line
  }, [switchActive, pageSize]);

  return (
    <div>
      <TopNavBar
        title={t("Addon Category")}
        isSearch
        buttonLabel="Create"
        isFilter
        functions={openDrawer}
        onChangeSearch={onAddOnSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      <div className={classes.root}>
        <TableComponent
          dataList={tData?.rows ?? []}
          Header={AddontableJson(onEdit)}
          cellClick={(rowData) => handleRowclick(rowData)}
          totalCount={tData?.count}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => handlePagination(newPage)}
        />
      </div>
      {/* drawer */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={open}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.title} noWrap>
                {edit?.isEdit ? "Edit" : " Create new"}
              </Typography>
              <Box>
                {!edit.isView && (
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={SaveNew}
                    className={classes.drawerButtonTittle}
                    disabled={loading}
                  >
                    {loading ? "saving..." : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.basicTittle}>Addon Category Logo</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LogoUpload
                    isrequired
                    logo_title={""}
                    handleChange={(value) => updateState("logo", value)}
                    data={data?.logo}
                    isError={data?.logo?.error ?? false}
                    errorMessage={data?.logo?.errorMsg ?? false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    value={data?.name}
                    type="text"
                    label="Addon Category Name"
                    placeholder="Enter  Addon Category Name"
                    errorMessage={data?.error?.name}
                    isError={data?.error?.name?.length > 0}
                    onChange={(e) => updateState("name", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    fullWidth={true}
                    isrequired
                    label="Description"
                    placeholder="Enter Description"
                    type="text"
                    multiline
                    value={data?.description}
                    errorMessage={data?.error?.description}
                    isError={data?.error?.description?.length > 0}
                    onChange={(e) => updateState("description", e.target.value)}
                  />
                </Grid>

                {/* commented these lines don't delete */}
                {/* <Grid item xs={6}>
                  <TextBox
                    fullWidth={true}
                    isReadonly={data?.unit_size?.length > 0 ? false : true}
                    label="Residential Unit Limit"
                    placeholder="Residential Unit Limit"
                    type="number"
                    value={data?.residential_unit_size}
                    errorMessage={data?.error?.residential_unit_size}
                    isError={data?.error?.residential_unit_size?.length > 0}
                    onChange={(e) =>
                      updateState("residential_unit_size", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    fullWidth={true}
                    isReadonly={data?.unit_size?.length > 0 ? false : true}
                    label="Commercial Unit Limit"
                    placeholder="Commercial Unit Limit"
                    type="number"
                    value={data?.commercial_unit_size}
                    errorMessage={data?.error?.commercial_unit_size}
                    isError={data?.error?.commercial_unit_size?.length > 0}
                    onChange={(e) =>
                      updateState("commercial_unit_size", e.target.value)
                    }
                  />
                </Grid> */}

              </Grid>
            </div>
          </div>
        }
      />
    </div>
  );
};
