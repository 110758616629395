import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { customerUseStyles } from "../styles";
import { Mobile, TextBox } from "../../../components";

const initial = {
  admin_name: "",
  is_active: true,
  admin_email_id: "",
  admin_mobile: "",
};

export const AdminForm = ({ update = () => false, file = [], state }) => {
  const classes = customerUseStyles();
  const [data, setData] = React.useState(file?.length > 0 ? file : []);

  //update stae
  const updateState = (key, value, index) => {
    let allProperty = [...data];
    allProperty[index] = {
      ...allProperty[index],
      [key]: value,
    };
    setData([...allProperty]);
  };

  //ondelete asset
  const onDeleteProof = (pIndex) => {
    const result = data.filter((i, index) => index !== pIndex);
    setData(result);
  };

  React.useEffect(() => {
    update(data?.filter((i) => i?.is_active));
    // eslint-disable-next-line
  }, [data]);

  const addAdmin = () => {
    setData([...data, { ...initial }]);
  };

  return (
    <Box className={classes.box} marginTop="12px">
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.title}>Admin Details</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              disabled={state?.isView}
              onClick={addAdmin}
            >
              Add Admin
            </Button>
          </Box>
        </Box>

        {data
          ?.filter((i) => i?.is_active)
          ?.map((val, index) => (
            <Grid container spacing={2} marginTop="12px">
              <Grid item xs={3.5}>
                <TextBox
                  isReadonly={state?.isView}
                  label="Admin Name"
                  placeholder="Enter Admin Name"
                  value={val?.admin_name}
                  onChange={(e) =>
                    updateState("admin_name", e.target.value, index)
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                <TextBox
                  isReadonly={state?.isView}
                  label="Admin Email Id"
                  placeholder="Enter Admin Email Id"
                  value={val?.admin_email_id}
                  onChange={(e) =>
                    updateState("admin_email_id", e.target.value, index)
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                {/* <TextBox
                  isReadonly={state?.isView}
                  label="Admin Mobile Number"
                  placeholder="Enter Admin Mobile Number"
                  value={val?.admin_mobile}
                  onChange={(e) =>
                    updateState("admin_mobile", e.target.value, index)
                  }
                /> */}
                <Mobile
                  label="Admin Mobile Number"
                  placeholder="Enter Admin Mobile Number"
                  value={val?.admin_mobile}
                  required
                  isReadonly={state?.isView}
                  handleChange={(e) => updateState("admin_mobile", e, index)}
                />
              </Grid>
              <Grid item xs={1.5} onClick={() => onDeleteProof(index)}>
                <Button
                  disabled={state?.isView}
                  color="error"
                  variant="contained"
                  sx={{ marginTop: "24px" }}
                >
                  {" "}
                  Delete
                </Button>
              </Grid>
            </Grid>
          ))}
      </Box>
    </Box>
  );
};
