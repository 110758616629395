import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { TextBox } from "../../../components";
import { CustomerCard } from "../../../components/customerCard";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { useStyles } from "../styles";

export const Customers = ({
  updateState = () => false,
  data = {},
  next = () => false,
}) => {
  const classes = useStyles();

  const [list, setList] = React.useState({
    data: [],
    loading: true,
    search: "",
    id: "",
  });
  console.log(list?.id, "ididid");
  const [serach, setSearch] = React.useState("");
  const getUserList = () => {
    const payload = {
      limit: 99999999,
      offset: 0
    }
    NetworkCall(
      `${config.api_rest}api/v1/customer/get/all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setList({
          data: response?.data?.data?.rows,
          id: response?.data?.data?.rows?.id,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setList({
          data: [],
          loading: false,
        });
      });
  };

  React.useEffect(() => {
    getUserList();
  }, []);

  const updateList = (key, value) => {
    setList({ ...list, [key]: value });
  };

  return (
    <Box p={2} className={classes.box}>
      <TextBox
        onChange={(e) => setSearch(e.target.value)}
        value={serach}
        label={null}
        placeholder="Search Customers"
      />
      <Box height={`calc(100vh - 220px)`} overflow="auto">
        {list?.data?.length === 0 ? (
          <Typography className={classes.title}>No Date Found</Typography>
        ) : (
          <Grid container spacing={1}>
            {list?.data
              ?.filter((val) => {
                if (list?.search === "") {
                  return val;
                } else if (
                  val?.company_name
                    ?.toLowerCase()
                    ?.includes(serach?.toLowerCase())
                ) {
                  return val;
                }
              })
              ?.map((i) => {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <CustomerCard
                      value={data?.customers}
                      onClick={() => {
                        updateState("customers", i);
                        next(2);
                      }}
                      data={i}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
