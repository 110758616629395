import React from "react";
import { Customers } from "./customers";
import { withNavBars } from "../../HOCs";

class CustomersParent extends React.Component {
  render() {
    return <Customers />;
  }
}

export default withNavBars(CustomersParent);
