import React from "react";
import { Plan } from "./plan";
import { withNavBars } from "../../HOCs";

class PlanParent extends React.Component {
  render() {
    return <Plan />;
  }
}

export default withNavBars(PlanParent);
