import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useState, useRef, useContext, useEffect } from "react";

import { ItemCard } from "./components/itemCard";
import { AlertProps, NetWorkCallMethods, fileUpload, useWindowDimensions } from "../../utils";
import { StatusOptions, constructGetConfigurationResponse, getGridLayout } from "./utils/common";
import { ConfigurationUseStyles } from "./style";
import { UseDebounce } from "../../components/customHooks/useDebounce";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertDialog, TextBox, TopNavBar } from "../../components";
import { useTranslation } from "react-i18next";
import { Label } from "../../components/label";
import LanguageIcon from '@mui/icons-material/Language';
import ExploreMetaIcon from "./assets/explore_meta_icon.png";
import { FileUploadCard } from "./components/fileUploadCard";
import { DeleteIcon } from "./components/itemCard/deleteIcon";

export const LogoConfiguration = () => {
    const { t = () => false } = useTranslation(["logoConfiguration"]);
    const file_upload_limit = 5;
    const allowed_file_format = [".jpg", ".jpeg", ".png"];
    const size = useWindowDimensions();
    const grid_layout = getGridLayout(size);
    const classes = ConfigurationUseStyles({ size: size });
    const [regionID, setRegionID] = useState("");
    const [login_logo_config_state, set_login_logo_config_state] = useState({});
    const [web_background_config_state, set_web_background_config_state] = useState({});
    const [mobile_background_config_state, set_mobile_background_config_state] = useState({});
    const [web_welcome_config_state, set_web_welcome_config_state] = useState({});
    const [mobile_welcome_config_state, set_mobile_welcome_config_state] = useState({});
    const login_logo_ref = useRef(null);
    const web_background_ref = useRef(null);
    const mobile_background_ref = useRef(null);
    const web_welcome_ref = useRef(null);
    const mobile_welcome_ref = useRef(null);
    const debounce = UseDebounce();
    const alert = useContext(AlertContext);
    const [is_button_disable, set_is_button_disable] = useState(false);
    const [appBuilds, setAppBuilds] = useState([]);
    const [isExploreMetaFormOpen, setIsExploreMetaFormOpen] = useState(false);
    const [exploreMetaState, setExploreMetaState] = useState({});
    const [exploreMetaFormState, setExploreMetaFormState] = useState({
        region_id: "",
        is_active: true,
        design: "design_1",
        information: "",
        cta_text: "",
        link: "",
        error: {
            information: "",
            cta_text: "",
            link: "",
        },
    });
    const [isPromotionPageMetaFormOpen, setIsPromotionPageMetaFormOpen] = useState(false);
    const [promotionPageMetaState, setPromotionPageMetaState] = useState({});
    const [promotionPageMetaFormState, setPromotionPageMetaFormState] = useState({
        is_active: true,
        design: "design_1",
        app_build: "",
        description: "",
        cta_text: "",
        mobile_background: "",
        error: {
            description: "",
            cta_text: "",
            mobile_background: "",
        },
    });
    const promotion_page_upload_file_ref = useRef(null);

    const handleChooseImage = (type) => {
        switch (type) {
            case "login_logo":
                login_logo_ref.current.click();
                break;
            case "web_background":
                web_background_ref.current.click();
                break;
            case "mobile_background":
                mobile_background_ref.current.click();
                break;
            case "web_welcome":
                web_welcome_ref.current.click();
                break;
            case "mobile_welcome":
                mobile_welcome_ref.current.click();
                break;

            default:
                break;
        }
    };

    const handleUploadImage = async (file, type) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const view = new DataView(arrayBuffer);
            const firstByte = view.getUint8(0);
            const secondByte = view.getUint8(1);

            const is_jpeg = firstByte === 0xFF && secondByte === 0xD8;
            const is_png = firstByte === 0x89 && secondByte === 0x50;

            if (is_jpeg || is_png) {
                handleBlobImage(file, type);

                fileUpload(file, {}, alert)
                    .then((response) => {
                        handleSuccessImage(response, type);
                    })
                    .catch((err) => {
                        handleErrorImage(type);
                    });
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("Invalid Image File"),
                    severity: AlertProps.severity.warning
                })
            }
        };

        reader.readAsArrayBuffer(file);
    };

    const handleBlobImage = (file, type) => {
        const local_image_url = URL.createObjectURL(file);

        let temp_selected_local_state = {
            selected_blob_image: file,
            selected_local_image: local_image_url,
            selected_image_name: `${t("Uploading")}...`,
            is_image_uploading: true,
            is_disable: true,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_selected_local_state,
                })
                break;

            default:
                break;
        }
    }

    const handleSuccessImage = (response, type) => {
        let temp_selected_remote_state = {
            selected_remote_image: response?.[0]?.url,
            selected_image_name: response?.[0]?.url?.split('/')?.reverse()?.[0],
            selected_local_image: null,
            is_image_uploading: false,
            is_disable: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }

    const handleErrorImage = (type) => {
        let temp_try_again_state = {
            selected_image_name: `${t("Failed. Try again")}...`,
            is_try_again: true,
            is_image_uploading: false,
            is_disable: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_try_again_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }

    const handleDeleteImage = (type) => {
        let temp_delete_image_state = {
            updated_local_image: null,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_delete_image_state,
                })
                break;

            default:
                break;
        }
    }

    const handleUpdateEdit = (type) => {
        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    is_edit_mode: true,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    is_edit_mode: true,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    is_edit_mode: true,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    is_edit_mode: true,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    is_edit_mode: true,
                })
                break;

            default:
                break;
        }
    }

    const handleClearImage = (type) => {
        let temp_clear_image_state = {
            updated_local_image: null,
            selected_remote_image: null,
            selected_local_image: null,
            is_try_again: false,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_clear_image_state,
                })
                break;

            default:
                break;
        }
    }

    const handleCancel = (type) => {
        let temp_clear_image_state = {
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: login_logo_config_state?.updated_image,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: web_background_config_state?.updated_image,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: mobile_background_config_state?.updated_image,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: web_welcome_config_state?.updated_image,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: mobile_welcome_config_state?.updated_image,
                })
                break;

            default:
                break;
        }
    }

    const handleSave = (url, type, state) => {
        let payload = { domains: [{ id: state?.region_id, [type]: url }] }
        if (url) {
            NetworkCall(
                `${config.api}api/v1/login_domain/upsert_domain`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                handleSaveImage(url, type);
                getConfiguration({ app_builds: appBuilds });
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error
                })
            })
        }
    }
    const handleSaveImage = (url, type) => {
        let temp_save_image_state = {
            updated_image: url,
            updated_local_image: url,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: url?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_save_image_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }
    const getConfiguration = ({ app_builds }) => {
        NetworkCall(
            `${config.api}api/v1/login_domain/domain_list`,
            NetWorkCallMethods.post, null, null, true, false
        ).then((response) => {
            const temp_data = response?.data?.data?.domainList?.[0];
            const temp_configuration_state = constructGetConfigurationResponse({ data: temp_data, app_builds });
            setRegionID(temp_configuration_state?.id);
            set_login_logo_config_state(temp_configuration_state?.login_logo);
            set_web_background_config_state(temp_configuration_state?.web_background);
            set_mobile_background_config_state(temp_configuration_state?.mobile_background);
            set_web_welcome_config_state(temp_configuration_state?.web_welcome);
            set_mobile_welcome_config_state(temp_configuration_state?.mobile_welcome);
            setExploreMetaState(temp_configuration_state?.explore_meta);
            setPromotionPageMetaState(temp_configuration_state?.promotion_page_meta);
            set_is_button_disable(false);
            // handleLoading(false);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }
    useEffect(() => {
        getAppBuilds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAppBuilds = () => {
        const headers = { "x-api-key": config.api_key };
        NetworkCall(
            `${config.property_automate_api}/pasm/app_build`,
            NetWorkCallMethods.post, { limit: null }, headers, true, false
        ).then((response) => {
            const temp_app_builds = response?.data?.data?.filter?.(_ => !["PG-MN-01"]?.includes(_?.build_code));
            setAppBuilds(temp_app_builds);
            getConfiguration({ app_builds: temp_app_builds });
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }


    const handleExploreMetaForm = ({ type = "open", app_build }) => {
        switch (type) {
            case "open":
                handleExploreMetaFormOpen({ app_build });
                break;
            case "close":
                handleExploreMetaFormClose();
                break;
            case "save":
                handleExploreMetaFormSave({ app_build });
                break;

            default:
                break;
        }
    }

    const handleExploreMetaFormOpen = ({ app_build }) => {
        const temp_state = {
            region_id: exploreMetaState?.[0]?.id,
            is_active: Boolean(exploreMetaState?.[0]?.is_active),
            design: exploreMetaState?.[0]?.design ?? "design_1",
            information: exploreMetaState?.[0]?.information ?? "",
            cta_text: exploreMetaState?.[0]?.cta_text ?? "",
            link: exploreMetaState?.[0]?.link ?? "",
            error: {
                information: "",
                cta_text: "",
                link: "",
            },
        }
        setExploreMetaFormState(temp_state);
        setIsExploreMetaFormOpen(true);
    }

    const handleExploreMetaFormClose = () => {
        setIsExploreMetaFormOpen(false);

        debounce(() => {
            setExploreMetaFormState({
                region_id: "",
                is_active: true,
                design: "design_1",
                information: "",
                cta_text: "",
                link: "",
                error: {
                    information: "",
                    cta_text: "",
                    link: "",
                },
            });
        }, 1000);
    }

    const handleExploreMetaFormSave = () => {
        set_is_button_disable(true);
        const payload = {
            domains: [{
                id: regionID,
                explore_meta: JSON.stringify([{
                    is_active: exploreMetaFormState?.is_active,
                    design: exploreMetaFormState?.design ?? "design_1",
                    information: exploreMetaFormState?.information,
                    cta_text: exploreMetaFormState?.cta_text,
                    link: exploreMetaFormState?.link,
                }])
            }]
        };

        if (validateExploreMetaForm()) {
            NetworkCall(
                `${config.api}api/v1/login_domain/upsert_domain`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then(() => {
                getConfiguration(({ app_builds: appBuilds }));
                alert.setSnack({
                    ...alert, open: true, msg: t("Explore Link Details Updated Successfully!!!"),
                    severity: AlertProps?.severity?.success
                });

                setIsExploreMetaFormOpen(false);

                debounce(() => {
                    set_is_button_disable(false);
                }, 1000);
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps?.severity?.error
                })
            })
        } else {
            set_is_button_disable(false);
            return false;
        }
    }

    const updateExploreMetaForm = ({ key, value }) => {
        let error = exploreMetaFormState?.error;
        error[key] = "";
        setExploreMetaFormState({ ...exploreMetaFormState, [key]: value, error })
    }

    const validateExploreMetaForm = () => {
        let isValid = true;
        let error = exploreMetaFormState.error;

        if (exploreMetaFormState?.information?.length === 0) {
            isValid = false;
            error.information = t("Required");
        } else {
            error.information = "";
        }

        if (exploreMetaFormState?.cta_text?.length === 0) {
            isValid = false;
            error.cta_text = t("Required");
        } else {
            error.cta_text = "";
        }

        // eslint-disable-next-line no-useless-escape
        var link = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(exploreMetaFormState?.link);

        if (exploreMetaFormState?.link?.length === 0) {
            isValid = false;
            error.link = t("Required");
        } else if (!link) {
            isValid = false;
            error.link = t("Enter a valid link");
        } else {
            error.link = "";
        }

        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps?.severity?.error,
                msg: t("Please fill all mandatory fields"),
            })
        }
        setExploreMetaFormState({ ...exploreMetaFormState, error });

        return isValid;
    }

    const handlePromotionPageMetaForm = ({ type = "open", app_build }) => {
        switch (type) {
            case "open":
                handlePromotionPageMetaFormOpen({ app_build });
                break;
            case "close":
                handlePromotionPageMetaFormClose();
                break;
            case "save":
                handlePromotionPageMetaFormSave({ app_build });
                break;

            default:
                break;
        }
    }

    const handlePromotionPageMetaFormOpen = ({ app_build }) => {
        const temp_promotion_page_meta = promotionPageMetaState?.find?.(pp => pp?.app_build?.build_code === app_build?.build_code)

        const temp_state = {
            is_active: Boolean(temp_promotion_page_meta?.is_active),
            design: temp_promotion_page_meta?.design ?? "design_1",
            app_build,
            description: temp_promotion_page_meta?.description ?? "",
            cta_text: temp_promotion_page_meta?.cta_text ?? "",
            mobile_background: temp_promotion_page_meta?.mobile_background ?? "",
            error: {
                description: "",
                cta_text: "",
                mobile_background: "",
            },
        }
        setPromotionPageMetaFormState(temp_state);
        setIsPromotionPageMetaFormOpen(true);
    }

    const handlePromotionPageMetaFormClose = () => {
        setIsPromotionPageMetaFormOpen(false);

        debounce(() => {
            setPromotionPageMetaFormState({
                is_active: true,
                design: "design_1",
                app_build: "",
                description: "",
                cta_text: "",
                mobile_background: "",
                error: {
                    description: "",
                    cta_text: "",
                    mobile_background: "",
                },
            });
        }, 1000);
    }

    const handlePromotionPageMetaFormSave = ({ app_build }) => {
        set_is_button_disable(true);

        const payload = {
            domains: [{
                id: regionID,
                promotion_page_meta: JSON.stringify(promotionPageMetaState?.map?.(_ => {
                    let final = {};

                    if (app_build?.build_code === _?.app_build?.build_code) {
                        final = {
                            is_active: Boolean(promotionPageMetaFormState?.is_active),
                            design: promotionPageMetaFormState?.design ?? "design_1",
                            app_build: _?.app_build,
                            description: promotionPageMetaFormState?.description,
                            cta_text: promotionPageMetaFormState?.cta_text,
                            mobile_background: promotionPageMetaFormState?.mobile_background,
                        }
                    } else {
                        final = {
                            is_active: Boolean(_?.is_active),
                            design: _?.design ?? "design_1",
                            app_build: _?.app_build,
                            description: _?.description,
                            cta_text: _?.cta_text,
                            mobile_background: _?.mobile_background,
                        }
                    }

                    return final;
                })),
            }]
        };

        if (validatePromotionPageMetaForm()) {
            NetworkCall(
                `${config.api}api/v1/login_domain/upsert_domain`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then(() => {
                getConfiguration(({ app_builds: appBuilds }));
                alert.setSnack({
                    ...alert, open: true, msg: t("Promotion Page Updated Successfully!!!"),
                    severity: AlertProps?.severity?.success
                });

                setIsPromotionPageMetaFormOpen(false);

                debounce(() => {
                    set_is_button_disable(false);
                }, 1000);
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps?.severity?.error
                })
            })
        } else {
            set_is_button_disable(false);
            return false;
        }
    }

    const updatePromotionPageMetaForm = ({ key, value }) => {
        let error = promotionPageMetaFormState?.error;
        error[key] = "";
        setPromotionPageMetaFormState({ ...promotionPageMetaFormState, [key]: value, error })
    }

    const validatePromotionPageMetaForm = () => {
        let isValid = true;
        let error = promotionPageMetaFormState.error;

        if (promotionPageMetaFormState?.description?.length === 0) {
            isValid = false;
            error.description = t("Required");
        } else {
            error.description = "";
        }

        if (promotionPageMetaFormState?.cta_text?.length === 0) {
            isValid = false;
            error.cta_text = t("Required");
        } else {
            error.cta_text = "";
        }

        if (promotionPageMetaFormState?.mobile_background?.length === 0) {
            isValid = false;
            error.mobile_background = t("Required");
        } else {
            error.mobile_background = "";
        }

        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps?.severity?.error,
                msg: t("Please fill all mandatory fields"),
            })
        }
        setPromotionPageMetaFormState({ ...promotionPageMetaFormState, error });

        return isValid;
    }

    const handlePromotionPageChooseImage = () => {//handlePromotionPageUploadImage
        promotion_page_upload_file_ref.current.click();
    };

    const handlePromotionPageUploadImage = async (file) => {
        set_is_button_disable(true);
        alert.setSnack({
            ...alert, open: true, msg: t("Mobile Background Uploading..."),
            severity: AlertProps.severity.info
        })
        const reader = new FileReader();

        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const view = new DataView(arrayBuffer);
            const firstByte = view.getUint8(0);
            const secondByte = view.getUint8(1);
            const thirdByte = view.getUint8(2);

            const is_jpeg = firstByte === 0xFF && secondByte === 0xD8;
            const is_png = firstByte === 0x89 && secondByte === 0x50;
            const is_gif = firstByte === 0x47 && secondByte === 0x49 && thirdByte === 0x46;

            if (is_jpeg || is_png || is_gif) {
                fileUpload(file, {}, alert)
                    .then((response) => {
                        updatePromotionPageMetaForm({ key: "mobile_background", value: response?.[0]?.url });
                        set_is_button_disable(false);
                        alert.setSnack({
                            ...alert, open: true, msg: t("Mobile Background Uploaded Successfully!!!"),
                            severity: AlertProps.severity.success
                        })
                    })
                    .catch((err) => {
                        set_is_button_disable(false);
                        alert.setSnack({
                            ...alert, open: true, msg: t("Something went wrong!!!"),
                            severity: AlertProps.severity.warning
                        })
                    });
            } else {
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true, msg: t("Invalid Image File"),
                    severity: AlertProps.severity.warning
                })
            }
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <div>
            <TopNavBar
                title={"Logo Configuration"}
            // isSearch
            // buttonLabel="Create Settings"
            // isFilter
            // functions={createPlan}
            />
            <Box className={classes.tab_content_div}>
                <Box className={classes.tab_content_inner_div}>
                    <Grid container direction={"row"} spacing={"16px"}
                        justifyContent={"start"}>

                        <ItemCard
                            handleChooseImage={handleChooseImage}
                            handleUploadImage={handleUploadImage}
                            handleUpdateEdit={handleUpdateEdit}
                            handleDeleteImage={handleDeleteImage}
                            handleClearImage={handleClearImage}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            grid_layout={grid_layout}
                            state={login_logo_config_state}
                            upload_file_ref={login_logo_ref} />
                        <ItemCard
                            handleChooseImage={handleChooseImage}
                            handleUploadImage={handleUploadImage}
                            handleUpdateEdit={handleUpdateEdit}
                            handleDeleteImage={handleDeleteImage}
                            handleClearImage={handleClearImage}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            grid_layout={grid_layout}
                            state={web_background_config_state}
                            upload_file_ref={web_background_ref} />
                        <ItemCard
                            handleChooseImage={handleChooseImage}
                            handleUploadImage={handleUploadImage}
                            handleUpdateEdit={handleUpdateEdit}
                            handleDeleteImage={handleDeleteImage}
                            handleClearImage={handleClearImage}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            grid_layout={grid_layout}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            state={mobile_background_config_state}
                            upload_file_ref={mobile_background_ref} />
                        <ItemCard
                            handleChooseImage={handleChooseImage}
                            handleUploadImage={handleUploadImage}
                            handleUpdateEdit={handleUpdateEdit}
                            handleDeleteImage={handleDeleteImage}
                            handleClearImage={handleClearImage}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            grid_layout={grid_layout}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            state={web_welcome_config_state}
                            upload_file_ref={web_welcome_ref} />
                        <ItemCard
                            handleChooseImage={handleChooseImage}
                            handleUploadImage={handleUploadImage}
                            handleUpdateEdit={handleUpdateEdit}
                            handleDeleteImage={handleDeleteImage}
                            handleClearImage={handleClearImage}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            grid_layout={grid_layout}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            state={mobile_welcome_config_state}
                            upload_file_ref={mobile_welcome_ref} />
                        <ItemCard
                            type="trigger_card"
                            state={{
                                updated_image: ExploreMetaIcon,
                                label: t("Explore Link Details"),
                            }}
                            handleForm={handleExploreMetaForm} />
                        {appBuilds?.map?.((_) => {
                            const mobile_background = promotionPageMetaState?.find?.(pp => pp?.app_build?.build_code === _?.build_code)?.mobile_background;
                            return <ItemCard
                                type="trigger_card"
                                state={{
                                    updated_image: mobile_background,
                                    label: `${t("Promotional Page")} - ${_?.build_name}`,
                                }}
                                appBuild={_}
                                handleForm={handlePromotionPageMetaForm}
                            />
                        })}
                    </Grid>
                </Box>
            </Box>
            <AlertDialog
                maxWidth={'sm'}
                open={isExploreMetaFormOpen}
                onClose={() => handleExploreMetaForm({ type: "close" })}
                header={t("Update Explore Link Details")}
                isNormal
                component={
                    <Stack className={classes.exploreMetaForm}
                        direction={"column"} rowGap={"16px"} p={"24px"}>
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Information")}
                            placeholder={t("Enter Information")}
                            multiline
                            rows={2}
                            color="white"
                            value={exploreMetaFormState?.information}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "information", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.information?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.information} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("CTA Text")}
                            placeholder={t("Enter CTA Text")}
                            color="white"
                            value={exploreMetaFormState?.cta_text}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "cta_text", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.cta_text?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.cta_text} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Link")}
                            placeholder={t("Enter Link")}
                            color="white"
                            endAdornment={<Box sx={{
                                display: "flex", paddingInlineStart: "4px",
                                justifyContent: "center", alignItems: "center",
                                borderInlineStart: "1px solid #98a0ac",
                            }}>
                                <LanguageIcon htmlColor="#98a0ac" fontSize="small" />
                            </Box>}
                            value={exploreMetaFormState?.link}
                            onChange={(e) =>
                                updateExploreMetaForm({ key: "link", value: e?.target?.value })}
                            isError={exploreMetaFormState?.error?.link?.length > 0}
                            errorMessage={exploreMetaFormState?.error?.link} />
                        <Stack direction={"column"} rowGap={"8px"}>
                            <Label label={t("Status")} isRequired labelMarginBottom={"0px"} />
                            <Stack direction={"row"} columnGap={"8px"}>
                                {StatusOptions({ t }).map((_) => {
                                    return <Button className={_?.value === exploreMetaFormState?.is_active ?
                                        classes.selectedButton :
                                        classes.unSelectedButton}
                                        disabled={is_button_disable}
                                        onClick={() => updateExploreMetaForm({ key: "is_active", value: _?.value })}>
                                        {_?.label}
                                    </Button>
                                })}
                            </Stack>
                        </Stack>
                        <Box height={"8px"} />
                        <Stack direction={"row"} columnGap={"16px"}>
                            <Button className={classes.exploreMetaFormCancelButton}
                                disabled={is_button_disable}
                                onClick={() => handleExploreMetaForm({ type: "close" })}
                                fullWidth>{t("Cancel")}</Button>
                            <Button className={classes.exploreMetaFormSaveButton}
                                disabled={is_button_disable}
                                onClick={() => handleExploreMetaForm({ type: "save", app_build: exploreMetaFormState?.app_build })}
                                fullWidth>{t("Save")}</Button>
                        </Stack>
                    </Stack>
                } />
            <AlertDialog
                maxWidth={'sm'}
                open={isPromotionPageMetaFormOpen}
                onClose={() => handlePromotionPageMetaForm({ type: "close" })}
                header={`${t("Update Promotion Page Details")} - ${promotionPageMetaFormState?.app_build?.build_name}`}
                isNormal
                component={
                    <Stack className={classes.exploreMetaForm}
                        direction={"column"} rowGap={"16px"} p={"24px"}>
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("Description")}
                            placeholder={t("Enter Description")}
                            multiline
                            rows={2}
                            maxLength={200}
                            color="white"
                            value={promotionPageMetaFormState?.description}
                            onChange={(e) =>
                                updatePromotionPageMetaForm({ key: "description", value: e?.target?.value })}
                            isError={promotionPageMetaFormState?.error?.description?.length > 0}
                            errorMessage={promotionPageMetaFormState?.error?.description} />
                        <TextBox
                            isrequired
                            isReadonly={is_button_disable}
                            label={t("CTA Text")}
                            placeholder={t("Enter CTA Text")}
                            maxLength={25}
                            color="white"
                            value={promotionPageMetaFormState?.cta_text}
                            onChange={(e) =>
                                updatePromotionPageMetaForm({ key: "cta_text", value: e?.target?.value })}
                            isError={promotionPageMetaFormState?.error?.cta_text?.length > 0}
                            errorMessage={promotionPageMetaFormState?.error?.cta_text} />
                        {promotionPageMetaFormState?.mobile_background
                            ? <Stack rowGap={"8px"}>
                                <Label isRequired label={t("Mobile Background")} />
                                <Stack direction={"row"} columnGap={"4px"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack direction={"row"} columnGap={"8px"}
                                        justifyContent={"space-between"} alignItems={"center"}>
                                        <Box className={classes.selected_image_div}>
                                            <img className={classes.selected_image}
                                                src={promotionPageMetaFormState?.mobile_background} alt="mobile_background" />
                                        </Box>
                                        <Typography className={classes.item_card_label}>
                                            {promotionPageMetaFormState?.mobile_background?.split('/')?.reverse()?.[0]}
                                        </Typography>
                                    </Stack>
                                    <Box className={classes.delete_button}
                                        onClick={() =>
                                            !is_button_disable && updatePromotionPageMetaForm({ key: "mobile_background", value: "" })}>
                                        <DeleteIcon />
                                    </Box>
                                </Stack>
                            </Stack>
                            : <FileUploadCard
                                t={t}
                                label={t("Mobile Background")}
                                file_upload_limit={file_upload_limit}
                                allowed_file_format={[...allowed_file_format, ".gif"]}
                                upload_file_ref={promotion_page_upload_file_ref}
                                handleChooseImage={() => handlePromotionPageChooseImage()}
                                handleUploadImage={(file) => handlePromotionPageUploadImage(file)}
                                errorMessage={promotionPageMetaFormState?.error?.mobile_background} />}
                        <Stack direction={"column"} rowGap={"8px"}>
                            <Label label={t("Status")} isRequired labelMarginBottom={"0px"} />
                            <Stack direction={"row"} columnGap={"8px"}>
                                {StatusOptions({ t }).map((_) => {
                                    return <Button className={_?.value === promotionPageMetaFormState?.is_active ?
                                        classes.selectedButton :
                                        classes.unSelectedButton}
                                        disabled={is_button_disable}
                                        onClick={() => updatePromotionPageMetaForm({ key: "is_active", value: _?.value })}>
                                        {_?.label}
                                    </Button>
                                })}
                            </Stack>
                        </Stack>
                        <Box height={"8px"} />
                        <Stack direction={"row"} columnGap={"16px"}>
                            <Button className={classes.exploreMetaFormCancelButton}
                                disabled={is_button_disable}
                                onClick={() => handlePromotionPageMetaForm({ type: "close" })}
                                fullWidth>{t("Cancel")}</Button>
                            <Button className={classes.exploreMetaFormSaveButton}
                                disabled={is_button_disable}
                                onClick={() => handlePromotionPageMetaForm({ type: "save", app_build: promotionPageMetaFormState?.app_build })}
                                fullWidth>{t("Save")}</Button>
                        </Stack>
                    </Stack>
                } />
        </div>
    )
}