

export const constructGetConfigurationResponse = ({ data = {}, app_builds = [] }) => {
    return {
        id: data?.id,
        login_logo: {
            type: "login_logo",
            label: "Login Logo",
            updated_image: data?.login_logo,
            updated_local_image: data?.login_logo,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.login_logo?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
            region_id: data?.id,
            region_name: data?.region,
        },
        web_background: {
            type: "web_background",
            label: "Web Background",
            updated_image: data?.web_background,
            updated_local_image: data?.web_background,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.web_background?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
            region_id: data?.id,
            region_name: data?.region,
        },
        mobile_background: {
            type: "mobile_background",
            label: "Mobile Background",
            updated_image: data?.mobile_background,
            updated_local_image: data?.mobile_background,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.mobile_background?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
            region_id: data?.id,
            region_name: data?.region,
        },
        web_welcome: {
            type: "web_welcome",
            label: "Web Welcome Background",
            updated_image: data?.web_welcome,
            updated_local_image: data?.web_welcome,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.web_welcome?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
            region_id: data?.id,
            region_name: data?.region,
        },
        mobile_welcome: {
            type: "mobile_welcome",
            label: "Mobile Welcome Background",
            updated_image: data?.mobile_welcome,
            updated_local_image: data?.mobile_welcome,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: data?.mobile_welcome?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
            region_id: data?.id,
            region_name: data?.region,
        },
        explore_meta: data?.explore_meta?.map?.((_) => {
            return {
                region_id: data?.id,
                is_active: Boolean(_?.is_active),
                design: _?.design ?? "design_1",
                information: _?.information ?? "",
                cta_text: _?.cta_text ?? "",
                link: _?.link ?? "",
                error: {
                    information: "",
                    cta_text: "",
                    link: "",
                },
            }
        }),
        promotion_page_meta: app_builds?.map?.((ab) => {
            const temp_promotion_page_meta = data?.promotion_page_meta?.find?.(pp => pp?.app_build?.build_code === ab?.build_code)
            return {
                is_active: Boolean(temp_promotion_page_meta?.is_active),
                design: temp_promotion_page_meta?.design ?? "design_1",
                app_build: ab,
                description: temp_promotion_page_meta?.description ?? "",
                cta_text: temp_promotion_page_meta?.cta_text ?? "",
                mobile_background: temp_promotion_page_meta?.mobile_background ?? "",
                error: {
                    description: "",
                    cta_text: "",
                    mobile_background: "",
                },
            }
        }),
        data: data,
    }
}

export const getGridLayout = (size) => {
    let grid = size?.width > 1300
        ? 4
        : size?.width > 800
            ? 6
            : 12;

    return grid;
}

export const StatusOptions = ({ t = () => false }) => [
    { label: t("Active"), value: true },
    { label: t("In-Active"), value: false },
]