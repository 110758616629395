import React from "react";
import { Grid } from "@mui/material";
import { TopNavBar } from "../../components";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import i18next from "i18next";
import { Typography } from "@mui/material";
import { useStylesSettings } from "./style";

export const Settings = (props) => {
  const classes = useStylesSettings(props);

  const { t } = useTranslation(["topBar"]);

  const handleLanguageChange = (e) => {
    i18next.changeLanguage(e.target.value);
  };

  return (
    <div>
      <TopNavBar
        title={t("settings")}
        // isSearch
        // buttonLabel="Create Settings"
        // isFilter
        // functions={createPlan}
      />
      <Grid container>
        <Grid xs={12} sm={12} md={6}>
          <Typography variant="subtitle2" className={classes.languageTypo}>
            {"Language"}
          </Typography>
          <Select
            value={localStorage.getItem("i18nextLng")}
            onChange={handleLanguageChange}
            className={classes.languageSelect}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"tamil"}>Tamil</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};
