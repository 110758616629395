import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import React from "react";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  AlertProps,
  allowed_file_size,
  Bold,
  findImageSize,
  SemiBold,
  singleFileUpload,
} from "../../utils";

const CustomTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "fontFamily" && prop !== "fontSize" && prop !== "color",
})(({ fontFamily, fontSize, color }) => ({
  fontFamily,
  fontSize,
  color,
}));

const ImageWrapper = styled("div")({
  width: 120,
  height: 120,
  borderRadius: 71,
  backgroundColor: "#F2F4F7",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CustomInput = styled("input")({
  position: "absolute",
  opacity: 0,
  inset: 0,
  zIndex: 1,
  cursor: "pointer",
});

const CustomAvatar = styled(Avatar)({
  width: "inherit",
  height: "inherit",
  borderRadius: "inherit",
});

const UploadButton = styled(Button)(({ theme }) => ({
  borderColor: "#E4E8EE",
  position: "relative",
  marginTop: theme.spacing(2),
  color: "#071741",
  background: "#FFFFFF",
  fontSize: 12,
  // fontFamily: SemiBold,
  cursor: "pointer",
  "&:hover": {
    background: "#FFFFFF",
  },
  borderRadius: theme.palette.borderRadius,
}));

const BuldingIcon = (props) => {
  const width = props?.width ? props?.width : 42;
  const height = props?.width ? props?.width : 40.001;
  const color = props?.width ? props?.width : "#ced3dd";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 40.001"
      {...props}
    >
      <path
        d="M17.16,4A5,5,0,0,0,12,9V27H8.5A5.516,5.516,0,0,0,3,32.5v10A1.5,1.5,0,0,0,4.5,44h39A1.5,1.5,0,0,0,45,42.5v-17A5.516,5.516,0,0,0,39.5,20H35V12.5a5.013,5.013,0,0,0-3.7-4.828l-13-3.5A5,5,0,0,0,17.16,4Zm-.092,3a1.937,1.937,0,0,1,.451.066l13,3.5A2,2,0,0,1,32,12.5V41H22V32.5A5.516,5.516,0,0,0,16.5,27H15V9a2,2,0,0,1,2.068-2ZM19,13a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Zm7,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Zm-7,7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1Zm7,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1Zm0,7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V28a1,1,0,0,0-1-1Zm12,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H38a1,1,0,0,1-1-1V28A1,1,0,0,1,38,27ZM8,34h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V35A1,1,0,0,1,8,34Zm7,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H15a1,1,0,0,1-1-1V35A1,1,0,0,1,15,34Zm11,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V35a1,1,0,0,0-1-1Zm12,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H38a1,1,0,0,1-1-1V35A1,1,0,0,1,38,34Z"
        transform="translate(-3 -3.999)"
        fill={color}
      />
    </svg>
  );
};
export const LogoUpload = (props) => {
  const {
    logo_title = "",
    accept = "image/*",
    buttonName = "Upload Image",
    fileUploadUrl = true,
    handleChange = () => false,
    data = {},
    placeholderLogo = "",
    profile = false,
    isReadonly = false,
    isError = false,
    errorMessage = "",
    isrequired = false,
  } = props;

  const theme = useTheme();
  const alert = React.useContext(AlertContext);

  const [loading, setLoading] = React.useState(false);

  const handleUpload = async (data) => {
    // const {height} = await findImageSize(data?.[0]);

    // if(height > 30){
    //     alert.setSnack({
    //         open: true,
    //         horizontal: AlertProps.horizontal.center,
    //         vertical: AlertProps.vertical.top,
    //         msg: `Image Height Shoud Be 30px`,
    //         severity: AlertProps.severity.error,
    //     });
    // }
    //only allow if file selected
    if (data?.length) {
      if (fileUploadUrl) {
        executeImageData(data);
      } else {
        handleChange && handleChange(data);
      }
    }
  };

  const executeImageData = async (data) => {
    setLoading(true);

    let uploaded_file = await singleFileUpload(
      data?.[0],
      { tenantId: `${config.tenantId}` },
      alert,
      allowed_file_size
    );
    if (uploaded_file?.[0]?.url) {
      handleChange && handleChange({ src: uploaded_file?.[0]?.url });
      setLoading(false);
    } else {
      handleChange(null);
      setLoading(false);
      alert.setSnack({
        open: true,
        horizontal: AlertProps.horizontal.center,
        vertical: AlertProps.vertical.top,
        msg: `Unable to upload`,
        severity: AlertProps.severity.error,
      });
    }
  };

  const removeImage = () => {
    handleChange && handleChange(null);
  };

  return (
    <div>
      <Stack alignItems={"center"}>
        {logo_title && (
          <CustomTypography
            // fontFamily={Bold}
            fontSize={12}
            color={theme.typography.color.secondary}
            sx={{
              mb: 2,
            }}
          >
            {logo_title}
            {isrequired && (
              <Typography
                variant="caption"
                style={{ color: "red", marginLeft: 4 }}
              >
                *
              </Typography>
            )}
          </CustomTypography>
        )}
        {loading ? (
          <Skeleton width={141} height={141} variant={"circular"} />
        ) : (
          <>
            <br />
            <ImageWrapper>
              {data?.src ? (
                <CustomAvatar src={data?.src} />
              ) : (
                <>
                  {placeholderLogo ? (
                    placeholderLogo
                  ) : profile ? (
                    <PersonOutlineIcon
                      sx={{ fontSize: "100px", color: "#E4E8EE" }}
                    />
                  ) : (
                    <BuldingIcon />
                  )}
                </>
              )}
            </ImageWrapper>
          </>
        )}
        {!isReadonly && (
          <>
            <br />
            {buttonName && (
              <UploadButton variant={"outlined"} disabled={loading}>
                {buttonName}
                <CustomInput
                  type="file"
                  onChange={(e) => handleUpload(e.target.files)}
                  accept={accept}
                />
              </UploadButton>
            )}
          </>
        )}
        {!isReadonly && (
          <>
            {data?.src && !loading && (
              <CustomTypography
                // fontFamily={Bold}
                fontSize={12}
                color={theme.palette.primary.main}
                onClick={removeImage}
                sx={{
                  mt: 1,
                  cursor: "pointer",
                }}
              >
                {"Remove Image"}
              </CustomTypography>
            )}
          </>
        )}
        {!isReadonly && (
          <>
            {isError && (
              <Typography variant={"caption"} color={"error"}>
                {errorMessage}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </div>
  );
};
