import { Box, Grid, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import { FileUploadIcon } from "./fileUploadIcon"
import { Label } from "../../../../components/label"

export const FileUploadCard = ({
    t = () => false,
    file_upload_limit,
    allowed_file_format,
    upload_file_ref,
    handleChooseImage = () => false,
    handleUploadImage = () => false,
    label = "",
    errorMessage = "",
}) => {
    const classes = useStyles()

    return (
        <Grid container spacing={"12px"}>
            {label && <Grid item xs={12}><Label isRequired label={label} /></Grid>}
            <Grid item xs={12}>
                <Stack className={classes.file_upload_div} direction={"row"}
                    justifyContent={"center"} alignItems={"center"}>
                    <FileUploadIcon />
                    <Box width={"12px"} />
                    <Typography className={classes.upload_file_text_cta}
                        onClick={handleChooseImage}>
                        {"Choose an image"}
                    </Typography>
                    <Typography className={classes.upload_file_text}>
                        &nbsp;{"to upload"}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        <Typography className={classes.upload_file_criteria_label}>
                            {"Supported Format:"}&nbsp;
                        </Typography>

                        <Typography className={classes.upload_file_criteria_value}>
                            {/* {".csv, .tsv, .xls, .xlsx, .txt, .pdf"} */}
                            {`"${allowed_file_format?.join(", ")}"`}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                        <Typography className={classes.upload_file_criteria_label}>
                            {"Maximum size:"}&nbsp;
                        </Typography>
                        <Typography className={classes.upload_file_criteria_value}>
                            {file_upload_limit?.toString() + "MB"}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <input type='file' id='file_upload'
                ref={upload_file_ref} style={{ display: 'none' }}
                accept={allowed_file_format}
                onChange={(e) => handleUploadImage(e?.target?.files?.[0])}
                onClick={(event) => { event.target.value = null }} />
            {errorMessage && <Typography variant={"caption"} color={"error"}>{errorMessage}</Typography>}
        </Grid>
    )
}