import React from "react";
import { useTranslation } from "react-i18next";
import { DrawerComponent, TableComponent, TopNavBar } from "../../components";
import { useFeatureStyles } from "./style";
import { FeatureData } from "./tableData";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Box } from "@mui/system";
import { Button, InputLabel, TextField, Typography } from "@mui/material";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import { UseDebounce } from "../../components/customHooks/useDebounce";

export const Features = () => {
  const classes = useFeatureStyles();
  const { t } = useTranslation(["topBar"]);
  const initialData = {
    id: "",
    name: "",
    description: "",
    is_active: false,
    error: {
      name: "",
      description: "",
    },
  };
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [featData, setFeatData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [Data, setData] = React.useState({ ...initialData });
  const [editDraw, setEditDraw] = React.useState({
    isEdit: false,
    id: "",
    isView: false,
  });
  // dialog
  const [drawopen, setDrawOpen] = React.useState(false);

  const alert = React.useContext(AlertContext);

  const isValid = () => {
    let isValid = true;
    let error = Data.error;

    if (Data.name === "") {
      isValid = false;
      error.name = "Enter Name";
    }

    if (Data.description === "") {
      isValid = false;
      error.description = "Enter Description";
    }

    setData({ ...Data, error });
    return isValid;
  };
  // save drawer
  const Save = () => {
    if (isValid()) {
      let dta = {
        feature_id: Data?.id,
        name: Data?.name,
        description: Data?.description,
        is_active: Data?.is_active,
      };
      setLoading(true);

      NetworkCall(
        `${config.api_rest}api/v1/feature/new`,
        NetWorkCallMethods.post,
        dta,
        null,
        true,
        false
      )
        .then((response) => {
          getFeature("", 0);
          let main = response.data;
          setLoading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          setDrawOpen(false);
        })
        .catch((err) => {
          setDrawOpen(false);
          setLoading(false);
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: Message.featureErr,
          });
        });
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    getFeature("", 0);
  }, []);

  // feature list
  const getFeature = (search, offset) => {
    let params = {
      search: search ? search : "",
      limit: pageSize,
      offset: offset,
    };
    NetworkCall(
      `${config.api_rest}api/v1/feature/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setFeatData(main);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // row handler
  const handleRow = () => { };

  // onchange handler
  const updateState = (key, value) => {
    setData({ ...Data, [key]: value });
  };

  // close drawer
  const closeDrawer = () => {
    setData(initialData);
    setDrawOpen(false);
  };

  const handleCreate = () => {
    setData(initialData);
    setDrawOpen(true);
  };
  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value * pageSize);
    getFeature("", offset)
  };


  const onFeatureGroupSearch = (search = "") => {
    debounce(() => searchTableFunction(search), 800);
  };


  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getFeature(e, 0);

  };
  // action edit
  const onDrawerEdit = (param, e) => { };
  return (
    <div>
      <TopNavBar
        title={t("features")}
        isSearch
        buttonLabel="Create"
        // isFilter
        functions={handleCreate}
        onChangeSearch={onFeatureGroupSearch}
      // handleFilterSwitchChange={handleFilterSwitchChange}
      // switchActive={switchActive}
      />
      <div className={classes.root}>
        <TableComponent
          dataList={featData?.rows ?? []}
          Header={FeatureData(onDrawerEdit)}
          cellClick={(Data) => handleRow(Data)}
          totalCount={featData?.count}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={(newPage) => handlePagination(newPage)}
        />
      </div>
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={drawopen}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.title} noWrap>
                Create new features
              </Typography>
              <Box>
                {!editDraw.isView && (
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={Save}
                    className={classes.drawerButtonTittle}
                  >
                    {loading ? "saving..." : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              <div className={classes.sections}>
                <InputLabel className={classes.label}>
                  feature Name<sup>*</sup>
                </InputLabel>
                <TextField
                  className={classes.input}
                  fullWidth
                  variant="outlined"
                  placeholder="features Name"
                  size="small"
                  type="text"
                  value={Data?.name}
                  onChange={(e) => updateState("name", e.target.value)}
                />

                {Data?.error?.name !== "" && (
                  <Typography style={{ fontSize: "10px", color: "red" }}>
                    {Data?.error?.name}
                  </Typography>
                )}

                <InputLabel className={classes.label}>
                  Description<sup>*</sup>
                </InputLabel>

                <TextField
                  className={classes.input}
                  placeholder="Description"
                  multiline
                  fullWidth
                  rows={2}
                  maxRows={4}
                  value={Data?.description}
                  onChange={(e) => updateState("description", e.target.value)}
                />
                {Data?.error?.description !== "" && (
                  <Typography style={{ fontSize: "10px", color: "red" }}>
                    {Data?.error?.description}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
