import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Grid, TextField, Button, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 8px 69px #0000001A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "520px",
    height: "520px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  root: {
    backgroundColor: "#3f51b5",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flexDirection: "column",
    display: "flex",
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  forgetPassword: {
    float: "right",
    fontSize: "12px",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px",
  },
}));

export const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation(["login"]);

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
    // eslint-disable-next-line
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    error: {
      email: "",
      password: "",
    },
  });

  const ValidateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = (key, value) => {
    if (key === "email") {
      if (ValidateEmail(value) === true) {
        let error = values.error;
        error[key] = "";
        setValues({ ...values, [key]: value });
      } else if (value === "") {
        let error = values.error;
        error[key] = "";
        setValues({ ...values, [key]: "" });
      } else {
        let error = values.error;
        error[key] = "Invalid Email";
        setValues({ ...values, [key]: "" });
      }
    } else {
      let error = values.error;
      error[key] = "";
      setValues({ ...values, [key]: value });
    }
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = values.error;

    if (values.email === "") {
      isValid = false;
      error.email = "Enter Email";
    }

    if (values.password === "") {
      isValid = false;
      error.password = "Enter Password";
    }
    if (values.email !== "" && values.password !== "") {
      isValid = true;
    }

    setValues({ ...values, error });
    return isValid;
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const onSendLoginBtnClicked = () => {
    if (isIamValideToLogin()) {
      var payload = {
        email_id: values.email,
        password: values.password,
      };
      NetworkCall(
        `${config.api}api/v1/pasm/login`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          localStorage.setItem(LocalStorageKeys.authToken, response.data.token);
          localStorage.setItem("profileId", response.data.profile_id);
          localStorage.setItem("user_name", response.data.username);
          history.push(Routes.dashboard);
        })
        .catch((err) => {});
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid
          container
          style={{ flexDirection: "column", alignItems: "center" }}
          spacing={2}
        >
          <Grid item>
            <Avatar sx={{ width: 76, height: 76 }} />
          </Grid>
          <Grid item>
            <Typography variant="h4">{t("login")}</Typography>
          </Grid>
          <Grid item style={{ width: "80%" }}>
            <div className={classes.item}>
              <label>{t("email")}</label>
              <TextField
                value={values.email_id}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {values.error.email !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {values.error.email}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item style={{ width: "80%" }}>
            <div className={classes.item}>
              <label>{t("password")}</label>
              <TextField
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={(e) => handleChange("password", e.target.value)}
                InputProps={{
                  endAdornment: values.showPassword ? (
                    <Visibility
                      onClick={handleClickShowPassword}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={handleClickShowPassword}
                      style={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
              {values.error.password !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {values.error.password}
                </Typography>
              )}
            </div>
            <span className={classes.forgetPassword}>
              <Typography variant="subtitle2">
                {t("forgot_password")}
              </Typography>
            </span>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={onSendLoginBtnClicked}
            >
              {t("login")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
