import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  box: {
    padding: "12px",
    backgroundColor: "white",
    borderRadius: "12px",
  },
  title: {
    fontSize: "14px",
    textAlign: "center",
    marginTop: "12px",
    fontFamily: "crayond_bold",
  },
  label: {
    fontSize: "16px",
    marginTop: "12px",
    fontFamily: "crayond_semiBold",
  },
}));
