import React from "react";
import { Addons } from "./addon";
import { withNavBars } from "../../HOCs";
import { AddonsNew } from "./addonNew";

class AddonsParent extends React.Component {
  render() {
    return <AddonsNew />;
  }
}

export default withNavBars(AddonsParent);
