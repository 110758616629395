import React from "react";
import { withNavBars } from "../../HOCs";
import { Demo } from "./demo";

class DemoParent extends React.Component {
  render() {
    return <Demo />;
  }
}
export default withNavBars(DemoParent);
