import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "./routes";
import { Access } from "./access";
import { LocalStorageKeys, NetWorkCallMethods } from "../utils";
import { useHistory } from "react-router-dom";
import { config } from "../config";
import { NetworkCall } from "../networkcall";

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = async (router) => {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      NetworkCall(
        `${config.api}api/v1/pasm/refresh`,
        NetWorkCallMethods.get,
        {},
        null,
        true,
        false
      )
        .then((res) => {
          console.log(res, "skslsk");
          const _ = Access("role", router?.match?.path);
          if (_ >= 0) {
            return true;
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.clear();
          return false;
        });
    } else {
      localStorage.clear();
      history.push(Routes.login);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
