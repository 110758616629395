import React from "react";
import { CreateCustomers } from "./createCustomers";
import { withNavBars } from "../../HOCs";

class CreateCustomersParent extends React.Component {
  render() {
    return <CreateCustomers />;
  }
}

export default withNavBars(CreateCustomersParent);
