import React from "react";
import { withNavBars } from "../../HOCs";
import { LogoConfiguration } from "./logoConfiguration";

class LogoConfigurationParent extends React.Component {
    render() {
        return <LogoConfiguration />;
    }
}

export default withNavBars(LogoConfigurationParent);
